import React, {useRef, useEffect, useState} from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {BASE_URL, ACCESS_TOKEN_NAME, USER_ID} from '../constants/apiConstants';

import IconButton from '@material-ui/core/IconButton';
import UndoIcon from '@material-ui/icons/Undo';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CreateIcon from '@material-ui/icons/Create';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

import Slider from '@material-ui/core/Slider';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { AlphaPicker, ChromePicker } from 'react-color';
import Popover from '@material-ui/core/Popover';
import './ChatWhiteboard.css'



function ChatWhiteboard(props) {


  const inbox = props.in_box

  const sendMessage = props.send_Message

  const conversation_id = props.conversation_id


  const canvasRef = useRef()
  const contextRef = useRef()

  const dummyCanvasRef = useRef()
  const dummyContextRef = useRef()

  const backgroundCanvasRef = useRef()
  const backgroundContextRef = useRef()

  const inboxRef = useRef()

  const [isDrawing, setIsDrawing] = useState(false)

  const [undo, undoSet] = useState([0,0,0,0,0])
  const [undocounts, undocountsSet] = useState(1)
  const [prevUndoCounts, prevUndoCountsSet] = useState(0)

  const [shapeSelector, shapeSelectorSet] = useState('pencil')
  const[cx, cxSet] = useState()
  const[cy, cySet] = useState()

  const [anchorEl, setAnchorEl] = useState(null);


  //const canvasHeight = window.innerHeight*0.85
  //const canvasWidth = window.innerWidth*0.8
  const canvasWidth = 1350
  const canvasHeight = canvasWidth/2
   // #######################################
  //#######  Whiteboard Toolbar ##############
  //###########################################

  const [brushThickness, brushThicknessSet] = useState(50)
  const [color, colorSet] = useState({hex: 'black',
                                      rgb: {r:'10', g:'50', b:'200',a:'0.5'}})
  const [lineStyle, lineStyleSet] = useState([])
  const [textInput, textInputSet] = useState('')
  const [backgroundImg, backgroundImgSet] = useState('')
  

  const handleBrushThickness = (e, value) => { 
   
    brushThicknessSet(value)
  }

  const handleColor = (value) => { 
   
    colorSet(value)
    //console.log(value)
    
  }

   const handleDelete = () => { 

    const date = new Date()
      
      sendMessage({     message:          'delete',                      
                        timestamp:        date,
                        message_type:     "WB",
                        conversation_id:  conversation_id,
                        x:                0, 
                        y:                0, 
                        command:          'DELETE' })
   
    
  }

  const handleSave = () => { 

    const token = localStorage.getItem(ACCESS_TOKEN_NAME);

    axios({
            method: "post",
            url: BASE_URL + "wb/save",
            data: {conversation_id: conversation_id,  whiteboard: canvasRef.current.toDataURL(), background : backgroundCanvasRef.current.toDataURL() },
            headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json'
        }
      })
          .then(res => {
            //console.log(res.data);
            
          })
          .catch(err => console.log(err))
  }


   const handleOpen = () => { 

    const token = localStorage.getItem(ACCESS_TOKEN_NAME);

      axios.get(BASE_URL + 'wb/conversation/' + conversation_id, {
          headers: {
            'Authorization': 'Bearer '+ token,
          }
        })

        .then((res) => {

          var canvasPic = new Image();

           canvasPic.src = res.data[0].whiteboard
           canvasPic.onload = function () { 

              contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
              contextRef.current.drawImage(canvasPic, 0, 0, canvasWidth, canvasHeight)}


          var bkgPic = new Image();

           bkgPic.src = res.data[0].background
           bkgPic.onload = function () { 

              backgroundContextRef.current.clearRect(0, 0, backgroundCanvasRef.current.width, backgroundCanvasRef.current.height)
              backgroundContextRef.current.drawImage(bkgPic, 0, 0, canvasWidth, canvasHeight)}
             

          //console.log(res.data[0])

           const date = new Date()
           sendMessage({    message:          'open',                      
                            timestamp:        date,
                            message_type:     "WB",
                            conversation_id:  conversation_id,
                            x:                0, 
                            y:                0, 
                            command:          'OPEN' })
   
    
  


         
        })

        .catch((error) => {
          console.error(error)
        }) 
  }

  const handleLineStyle = (value) => { 
   
    if(!lineStyle[0]){
    lineStyleSet([5,15])}
    else{
      lineStyleSet([])
    }
    
  }

  const handleShapeSelector = (e,value) => { 
   
    shapeSelectorSet(value)
    
    
  }

   const handleTextInput = (e) => { 
   
    
    textInputSet(e.target.value)
    
    
  }
  
  const HandleInsertImage = (e) => { 
   
   var extension = e.target.files[0].type
   
   var reader = new FileReader();
   var img = new Image();
   
   reader.onloadend = function() {

    var img = new Image();
    img.onload = function() {

      var H = img.height
      var W = img.width
      var ox = 0
      var oy = 0

      if (img.height > canvasHeight | img.width > canvasWidth){
        if(img.height>=img.width){ 
          
          if(img.height/img.width >= canvasHeight/canvasWidth){
          W = img.width*(canvasHeight/img.height); H = canvasHeight}

          if(img.height/img.width < canvasHeight/canvasWidth){
          H = img.height*(canvasWidth/img.width); W = canvasWidth}
        }

        if(img.height<img.width){ 
          
          if(img.height/img.width >= canvasHeight/canvasWidth){
          W = img.width*(canvasHeight/img.height); H = canvasHeight}

          if(img.height/img.width < canvasHeight/canvasWidth){
          H = img.height*(canvasWidth/img.width); W = canvasWidth}
        }
      }

      var ox = ((canvasWidth) - W)/2
      var oy = ((canvasHeight) - H)/2

      backgroundContextRef.current.drawImage(img, ox, oy, W,H)

      const date = new Date()

        const token = localStorage.getItem(ACCESS_TOKEN_NAME);

        axios({
                method: "post",
                url: BASE_URL + "wb/message",
                data: {conversation_id: conversation_id, whiteboard: canvasRef.current.toDataURL(), background : backgroundCanvasRef.current.toDataURL() },
                headers: {
                'Authorization': 'Bearer '+ token,
                'Content-Type': 'application/json'
            }
          })
              .then(res => {
                //console.log(res.data);

                if(res.status === 201){

                  console.log('201!!')

                  sendMessage({     message:          'backgroundCanvasRef.current.toDataURL()',                      
                                    timestamp:        date,
                                    message_type:     "WB",
                                    conversation_id:  conversation_id,
                                    x:                0, 
                                    y:                0, 
                                   command:          'BKG'})


                }
                
              })
              .catch(err => console.log(err))

         //sendMessage({    message:          'backgroundCanvasRef.current.toDataURL()',                      
         //                 timestamp:        date,
         //                 message_type:     "WB",
         //                 conversation_id:  conversation_id,
         //                 x:                0, 
         //                 y:                0, 
         //                 command:          'BKG'})

    }
    
    img.src = reader.result
    
   
         
   }    
   
   reader.readAsDataURL(e.target.files[0]); 




  }


  const handlePopClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

 const popOpen = Boolean(anchorEl);




  
  useEffect(() => {

    const canvas = canvasRef.current

    canvas.width = canvasWidth
    canvas.height = canvasHeight
    const context = canvas.getContext('2d')
    contextRef.current = context


    const dummyCanvas = dummyCanvasRef.current

    dummyCanvas.width = canvasWidth
    dummyCanvas.height = canvasHeight
    const dummyContext = dummyCanvas.getContext('2d')
    dummyContextRef.current = dummyContext

    const backgroundCanvas = backgroundCanvasRef.current

    backgroundCanvas.width = canvasWidth
    backgroundCanvas.height = canvasHeight
    const backgroundContext = backgroundCanvas.getContext('2d')
    backgroundContextRef.current = backgroundContext

  },[])

  useEffect(() => {


    contextRef.current.lineCap = 'round'
    contextRef.current.strokeStyle = 'rgba(' + color.rgb.r + ', '+ color.rgb.g + ', '+ color.rgb.b + ', '+ color.rgb.a +')'
    contextRef.current.lineWidth = brushThickness/4
    contextRef.current.setLineDash(lineStyle)
    contextRef.current.fillStyle = 'rgba(' + color.rgb.r + ', '+ color.rgb.g + ', '+ color.rgb.b + ', '+ color.rgb.a +')'
    contextRef.current.font = brushThickness+"px Arial";


    dummyContextRef.current.lineCap = 'round'
    dummyContextRef.current.strokeStyle = 'rgba(' + color.rgb.r + ', '+ color.rgb.g + ', '+ color.rgb.b + ', '+ color.rgb.a +')'
    dummyContextRef.current.lineWidth = brushThickness/4
    dummyContextRef.current.setLineDash(lineStyle)


  },[brushThickness, color, lineStyle])


  // ########################################################
  // ########################################################
  // ###########      REMOTE CONTROL         ################
  // ########################################################
  // ########################################################
 
 useEffect(() => {

  if (inbox[inbox.length-1] && inbox[inbox.length-1].command == 'DRAW'){

        //contextRef.current.strokeStyle = 'red'
        //contextRef.current.lineWidth = 70

        var canvasPic = new Image();

        canvasPic.src = inbox[inbox.length-1].message;
        canvasPic.onload = function () { 


          contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
          contextRef.current.drawImage(canvasPic, 0, 0, canvasWidth, canvasHeight)}

        //undoSet(JSON.pox.length-1].command))

        const dummyArray = undo
        dummyArray[undocounts] = inbox[inbox.length-1].message
        //dummyArray[undocounts] = 1
        undoSet(dummyArray)

        //console.log('undoCounts is: ' + undocounts + ' PrevundoCounts is: ' + prevUndoCounts)
        //console.log('undo is: ' + undo)

        

        if (undocounts==4)
           {undocountsSet(0)}
         else{
         undocountsSet(undocounts+1)}

         if(prevUndoCounts==4)
           {prevUndoCountsSet(0)}
         else{
         prevUndoCountsSet(prevUndoCounts+1)}

        
   }

   if (inbox[inbox.length-1] && inbox[inbox.length-1].command == 'BKG'){

        //contextRef.current.strokeStyle = 'red'
        //contextRef.current.lineWidth = 70
        

        var canvasPic = new Image();

        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
          
          
          console.log('BKG received')

       
          
          axios.get(BASE_URL + "wb/message/" + conversation_id, {
            headers: {
              'Authorization': 'Bearer '+ token,
            }
          })

          .then((res) => {

              console.log(res.data)

              var canvasPic = new Image();
              canvasPic.src = res.data[0].background
              canvasPic.onload = function () { 


              backgroundContextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
              backgroundContextRef.current.drawImage(canvasPic, 0, 0, canvasWidth, canvasHeight)}

              
                    
              })

          .catch((error) => {
            console.error(error)
          })  

        //canvasPic.src = inbox[inbox.length-1].message;
        //canvasPic.onload = function () { 


          //backgroundContextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
          //backgroundContextRef.current.drawImage(canvasPic, 0, 0, canvasWidth, canvasHeight)}

        //undoSet(JSON.pox.length-1].command))        
   }

   if (inbox[inbox.length-1] && inbox[inbox.length-1].command == 'OPEN'){

    handleOpen()

   }

   if (inbox[inbox.length-1] && inbox[inbox.length-1].message == 'UNDO'){


      if(undocounts==0){undocountsSet(4)}
         else{
         undocountsSet(undocounts-1)}

         if(prevUndoCounts==0){prevUndoCountsSet(4)}
         else{
         prevUndoCountsSet(prevUndoCounts-1)}

        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        var canvasPic = new Image();

        canvasPic.src = undo[prevUndoCounts];
        canvasPic.onload = function () { contextRef.current.drawImage(canvasPic, 0, 0, canvasWidth, canvasHeight)}

   }

   if (inbox[inbox.length-1] && inbox[inbox.length-1].command == 'DELETE'){

      contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      backgroundContextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    if (undocounts==4)
           {undocountsSet(0)}
         else{
         undocountsSet(undocounts+1)}

         if(prevUndoCounts==4)
           {prevUndoCountsSet(0)}
         else{
         prevUndoCountsSet(prevUndoCounts+1)}


   }
 

 },[inbox])

{/*
   
 useEffect(() => {
   
   const lastCommand = inbox[inbox.length-1]

   //if(inbox[inbox.length-1]){console.log('last command is '+ inbox[inbox.length-1].message)}

   if (inbox[inbox.length-1] && inbox[inbox.length-1].message == 'onMouseDown'){


        if(shapeSelector=='circle'||'text'){

             cxSet(inbox[inbox.length-1].x)
             cySet(inbox[inbox.length-1].y)
           }

        if(shapeSelector=='pencil'){
            
            contextRef.current.beginPath()
            contextRef.current.moveTo(inbox[inbox.length-1].x, inbox[inbox.length-1].y)

            dummyContextRef.current.beginPath()
            dummyContextRef.current.moveTo(inbox[inbox.length-1].x, inbox[inbox.length-1].y)
         }


        setIsDrawing(true)

        const dummyArray = undo
        dummyArray[undocounts] = canvasRef.current.toDataURL()
        undoSet(dummyArray)

        if (undocounts==4)
           {undocountsSet(0)}
         else{
         undocountsSet(undocounts+1)}

         if(prevUndoCounts==4)
           {prevUndoCountsSet(0)}
         else{
         prevUndoCountsSet(prevUndoCounts+1)}
      
   }

   if(inbox[inbox.length-1] && inbox[inbox.length-1].message =='onMouseUp'){

          setIsDrawing(false)

          if(shapeSelector=='circle'){
            contextRef.current.stroke();
          }

          dummyContextRef.current.clearRect(0,0,canvasRef.current.width,canvasRef.current.height);

          if(shapeSelector=='text'){
           contextRef.current.textAlign = "center";
           contextRef.current.fillText(textInput, cx, cy);
         }  
   }

  if(inbox[inbox.length-1] && inbox[inbox.length-1].message =='onMouseMove'){


    if(!isDrawing){
            return
          }
          if(shapeSelector=='pencil'){
              
              contextRef.current.lineTo(inbox[inbox.length-1].x, inbox[inbox.length-1].y) 
              contextRef.current.stroke()

              dummyContextRef.current.lineTo(inbox[inbox.length-1].x, inbox[inbox.length-1].y) 
              dummyContextRef.current.stroke()

        }

        if(shapeSelector=='circle'){

          
          
          const x = inbox[inbox.length-1].x
          const y = inbox[inbox.length-1].y

   
         
          
          //contextRef.current.clearRect(0,0,canvasRef.current.width,canvasRef.current.height);
          contextRef.current.beginPath();
          contextRef.current.moveTo(cx, cy + (y-cy)/2);
          contextRef.current.bezierCurveTo(cx, cy, x, cy, x, cy + (y-cy)/2);
          contextRef.current.bezierCurveTo(x, y, cx, y, cx, cy + (y-cy)/2);
          contextRef.current.closePath();
          //contextRef.current.stroke();


          dummyContextRef.current.clearRect(0,0,canvasRef.current.width,canvasRef.current.height);
          dummyContextRef.current.beginPath();
          dummyContextRef.current.moveTo(cx, cy + (y-cy)/2);
          dummyContextRef.current.bezierCurveTo(cx, cy, x, cy, x, cy + (y-cy)/2);
          dummyContextRef.current.bezierCurveTo(x, y, cx, y, cx, cy + (y-cy)/2);
          //dummyContextRef.current.closePath();
          dummyContextRef.current.stroke();
        }



  }



  },[inbox])


 
*/}
  

  const startDrawing = ({nativeEvent}) => { 


        //const {offsetX, offsetY} = nativeEvent

        //const date = new Date()
        //sendMessage({   message:          'onMouseDown',                      
        //                timestamp:        date,
        //                message_type:     "WB",
        //                conversation_id:  conversation_id,
        //                x:                offsetX, 
        //                y:                offsetY, 
        //                command:          'test'})

        //console.log(inbox)

        
       if(shapeSelector=='pencil'){
            const {offsetX, offsetY} = nativeEvent
            contextRef.current.beginPath()
            contextRef.current.moveTo(offsetX, offsetY)

            dummyContextRef.current.beginPath()
            dummyContextRef.current.moveTo(offsetX, offsetY)
         }

         if(shapeSelector=='circle'||'text'){

           const {offsetX, offsetY} = nativeEvent
           cxSet(offsetX)
           cySet(offsetY)
         }

        
        
        setIsDrawing(true)

        {/*
        const dummyArray = undo
        dummyArray[undocounts] = canvasRef.current.toDataURL()
        undoSet(dummyArray)




        if (undocounts==4)
           {undocountsSet(0)}
         else{
         undocountsSet(undocounts+1)}

         if(prevUndoCounts==4)
           {prevUndoCountsSet(0)}
         else{
         prevUndoCountsSet(prevUndoCounts+1)}
       */}

       

    }

    const finishDrawing = () => { 

      
         
         //contextRef.current.closePath()
         
         setIsDrawing(false)

         //const dummyArray = undo
         //dummyArray[undocounts] = canvasRef.current.toDataURL()
         //dummyArray[undocounts] = 1
        //undoSet(dummyArray)

         if(shapeSelector=='pencil'){
         const date = new Date()
         sendMessage({    message:          canvasRef.current.toDataURL(),                      
                          timestamp:        date,
                          message_type:     "WB",
                          conversation_id:  conversation_id,
                          x:                0, 
                          y:                0, 
                          command:          'DRAW'})
       }

         if(shapeSelector=='circle'){
         contextRef.current.stroke();

         

         const date = new Date()
         sendMessage({     message:       canvasRef.current.toDataURL(),                      
                        timestamp:        date,
                        message_type:     "WB",
                        conversation_id:  conversation_id,
                        x:                0, 
                        y:                0, 
                        command:          'DRAW'})
       }

         dummyContextRef.current.clearRect(0,0,canvasRef.current.width,canvasRef.current.height);

          if(shapeSelector=='text'){

           contextRef.current.textAlign = "center";
           contextRef.current.fillText(textInput, cx, cy);

           

           const date = new Date()
           sendMessage({     message:          canvasRef.current.toDataURL(),                      
                        timestamp:        date,
                        message_type:     "WB",
                        conversation_id:  conversation_id,
                        x:                0, 
                        y:                0, 
                        command:          'DRAW' })
         }


         
    }

    const draw = ({nativeEvent}) => {

          if(!isDrawing){
            return}

          const {offsetX, offsetY} = nativeEvent
          //const date = new Date()
          //sendMessage({   message:          'onMouseMove',                      
          //                timestamp:        date,
          //                message_type:     "WB",
          //                conversation_id:  conversation_id,
          //                x:                offsetX, 
          //                y:                offsetY, 
          //                command:          'test'})

         
          if(shapeSelector=='pencil'){
              const {offsetX, offsetY} = nativeEvent
              contextRef.current.lineTo(offsetX, offsetY) 
              contextRef.current.stroke()

              dummyContextRef.current.lineTo(offsetX, offsetY) 
              dummyContextRef.current.stroke()

        }

        if(shapeSelector=='circle'){

          //var {offsetX, offsetY} = nativeEvent
          
          const x = offsetX
          const y = offsetY

   
         
          
          //contextRef.current.clearRect(0,0,canvasRef.current.width,canvasRef.current.height);
          contextRef.current.beginPath();
          contextRef.current.moveTo(cx, cy + (y-cy)/2);
          contextRef.current.bezierCurveTo(cx, cy, x, cy, x, cy + (y-cy)/2);
          contextRef.current.bezierCurveTo(x, y, cx, y, cx, cy + (y-cy)/2);
          contextRef.current.closePath();
          //contextRef.current.stroke();


          dummyContextRef.current.clearRect(0,0,canvasRef.current.width,canvasRef.current.height);
          dummyContextRef.current.beginPath();
          dummyContextRef.current.moveTo(cx, cy + (y-cy)/2);
          dummyContextRef.current.bezierCurveTo(cx, cy, x, cy, x, cy + (y-cy)/2);
          dummyContextRef.current.bezierCurveTo(x, y, cx, y, cx, cy + (y-cy)/2);
          //dummyContextRef.current.closePath();
          dummyContextRef.current.stroke();
        }

        
              
    }


      const undoFunction = () => {

        const date = new Date()
         sendMessage({     message:       'UNDO',                      
                        timestamp:        date,
                        message_type:     "WB",
                        conversation_id:  conversation_id,
                        x:                0, 
                        y:                0, 
                        command:          'UNDO'})

        {/*}
        if(undocounts==0){undocountsSet(4)}
         else{
         undocountsSet(undocounts-1)}

         if(prevUndoCounts==0){prevUndoCountsSet(4)}
         else{
         prevUndoCountsSet(prevUndoCounts-1)}

        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        var canvasPic = new Image();

        canvasPic.src = undo[prevUndoCounts];
        canvasPic.onload = function () { contextRef.current.drawImage(canvasPic, 0, 0)}
        */}
      

    }    
    

  return (

    <div>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor:'rgba(120,120,120,0.1)', marginLeft:'auto', 
                    marginRight:'auto', borderRadius:'20px', marginTop:'7px', marginBottom:'7px', padding: '4px', paddingRight:'20px'}}>

        

        <div style={{ fontVariant:'small-caps', fontSize:'15px'}}>
          <div style={{ marginLeft:'10px'}}>
           Brush thickness
          </div>
           
          <Slider value = {brushThickness} valueLabelDisplay='auto' onChange = {handleBrushThickness} aria-labelledby="continuous-slider" style={{maxWidth:'200px', marginLeft:'10px'}}/>
        </div>

        <ToggleButton
          value="check"
          selected = {lineStyle[0]}
          onChange = {handleLineStyle}
          style={{ marginLeft:'25px',}}  
        >
            <LineStyleIcon />
        </ToggleButton>

        <ToggleButtonGroup

            value={shapeSelector}
            exclusive
            onChange={handleShapeSelector}
            aria-label="text alignment"
            style={{ marginLeft:'5px',}} 
          >
            <ToggleButton value="pencil" aria-label="pencil">
              <CreateIcon />
            </ToggleButton>
            <ToggleButton value="circle" aria-label="circle">
              <RadioButtonUncheckedIcon />
            </ToggleButton>
            <ToggleButton value="text" aria-label="text">
              <TextFieldsIcon/>
            </ToggleButton>
          </ToggleButtonGroup>

          <input type="file" accept="image/*" onChange={HandleInsertImage} id="insertImage" style={{display: "none"}}/>
                      <label htmlFor="insertImage" >
                       
                       <ToggleButton value = 'dummy' component= 'span'> <ImageIcon/> </ToggleButton>
                       
                      </label>
         
          <TextField variant = 'outlined' size = 'small' value = {textInput} onChange = {handleTextInput} label = 'type text' style = {{marginLeft: '5px'}}/> 



        <IconButton  variant="contained" onClick={handlePopClick} style={{ borderStyle:'solid', borderWidth:'1px', borderColor:'grey', height:'50px', width:'50px', marginLeft:'20px', backgroundColor: 'rgba(' + color.rgb.r + ', '+ color.rgb.g + ', '+ color.rgb.b + ', '+ color.rgb.a +')'}}>
        <ColorLensIcon/>
      </IconButton>
      <Popover
        
        open={popOpen}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ChromePicker color = {color.rgb} onChangeComplete={handleColor} disableAlpha = {false}/>
      </Popover>
    
        

        <IconButton onClick = {undoFunction} style={{ borderStyle:'solid', borderWidth:'1px', borderColor:'grey', height:'50px', width:'50px', marginLeft:'20px'}} > <UndoIcon/></IconButton>
        <IconButton onClick = {handleDelete} style={{ borderStyle:'solid', borderWidth:'1px', borderColor:'grey' , height:'50px', width:'50px'}} > <DeleteOutlineIcon/></IconButton>  
        <IconButton onClick = {handleOpen} style={{ borderStyle:'solid', borderWidth:'1px', borderColor:'grey' , height:'50px', width:'50px'}} > <FolderOpenIcon/></IconButton> 
        <IconButton onClick = {handleSave} style={{ borderStyle:'solid', borderWidth:'1px', borderColor:'grey' , height:'50px', width:'50px'}} > <SaveOutlinedIcon/></IconButton>   
      </div>

      <div style={{position:'relative',}}>

         <div className = 'canvasContainer'>
           <canvas

            id = 'backgroundCanvas'
            ref = {backgroundCanvasRef}
            className = "canvas"
           />

           <canvas

            id = 'mainCanvas'
            onMouseDown = {startDrawing}
            onMouseUp = {finishDrawing}
            onMouseMove = {draw}
            ref = {canvasRef}
            className = "canvas"
           />

           <canvas

            id = 'dummyCanvas'
            onMouseDown = {startDrawing}
            onMouseUp = {finishDrawing}
            onMouseMove = {draw}
            ref = {dummyCanvasRef}
            className = "canvas"
         />
         </div>

        

         
      </div>

     </div>
  	  

   
  );
}

export default ChatWhiteboard;