import React, {useState, useEffect} from 'react';
import {TextField, Button } from '@material-ui/core';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import img_default from "./cretto_burri.jpg";
import './Settings.css'
import {BASE_URL, ACCESS_TOKEN_NAME, USER_ID, USER_TYPE} from '../constants/apiConstants'

import PortfolioEntry from './SettingPortfolioEntry'
import AlertComponent from './AlertComponent';

function SettingsPortfolio(props) {

const [errorMessage, updateErrorMessage] = useState(null);
const [severity, severityUpdate] = useState("success");
const [refresh, refreshSet] = useState([]);

const [index, indexSet] = useState([1,2,3,4,5,6,7,8,9,10])

const [title, titleSet] = useState('') 
const [matter, matterSet] = useState('') 
const [dimension_one, dimension_oneSet] = useState()
const [dimension_two, dimension_twoSet] = useState()
const [tags, tagsSet] = useState()

const [loading, loadingSet] = useState(true)

const userType = localStorage.getItem(USER_TYPE);


const [imgURLnew, imgURLnewSet] = useState( () =>{
        
        const index2 = [1,2,3,4,5,6,7,8,9,10]
        const output = {}
        index2.map((currentIndex, i) => (

        	output['URLimage'+currentIndex] = img_default

			               
			                ))
        return output
})

//console.log(imgURLnew)      
	
const [imgURL, imgURLSet] = useState({

	    URLimage1 : img_default,
        URLimage2 : img_default,
        URLimage3 : img_default,
        URLimage4 : img_default,
        URLimage5 : img_default,
        URLimage6 : img_default,
        URLimage7 : img_default,
        URLimage8 : img_default,
        URLimage9 : img_default,
        URLimage10 : img_default,
        URLdescription1: '',
        URLdescription2: '',
	    URLdescription3: '',
		URLdescription4: '',
		URLdescription5: '',
		URLdescription6: '',
		URLdescription7: '',
		URLdescription8: '',
		URLdescription9: '',
		URLdescription10: '',})



const [imgObj , setImgObj] = useState({
        image1 : null,
        image2 : null,
        image3 : null,
        image4 : null,
        image5 : null,
        image6 : null,
        image7 : null,
        image8 : null,
        image9 : null,
        image10 : null,
        description1 : "",
        description2 : "",
        description3 : "",
        description4 : "",
        description5 : "",
        description6 : "",
        description7 : "",
        description8 : "",
        description9 : "",
        description10 : "",
        
    })


 useEffect(() => {
    
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const userID = localStorage.getItem(USER_ID);
    

        axios.get(BASE_URL + 'users/image/portfolio/'+userID, {
          headers: {
            'Authorization': 'Bearer '+ token,
          }
        })

        .then((res) => {

        	console.log(res.data)


          	res.data.forEach(imgArraySet);
          	

				function imgArraySet(value) {

					  let imageKey = 'URLimage'+value.index
					  let descrkey = 'URLdescription'+value.index

					  //const dummy = {}

					  imgURLSet(prevState => ({
					            ...prevState,
					            [imageKey] :  value.original,
					            [descrkey] :  value.description
					        }))

					  //value.description.split(';')[0]

					  //dummy[imageKey] = value.original

					  //return dummy

				}

				loadingSet(false)	
               
			   
        })

        .catch((error) => {
          console.error(error)
        })    

     

 }, [refresh]);




    const handleChange = (e) => {
        const {id , value} = e.target   
        setImgObj(prevState => ({
            ...prevState,
            [id] : value
        }))
     console.log(imgObj);
     

    }



	  const handleImageChange = (e) => {
	    
	  	const {id} = e.target

	    setImgObj(prevState => ({
            ...prevState,
            [id] : e.target.files[0]


        }))

        //console.log(e.target.files[0])

        let reader = new FileReader();
        reader.onload = function(ev){

        let keyID = 'URL'+id

        imgURLSet(prevState => ({
            ...prevState,
            [keyID] : ev.target.result


        }))


        }

        if(e.target.files[0]){

      reader.readAsDataURL(e.target.files[0])}

       	        

	  };


	  const handleSubmit = (e) => { 

	    e.preventDefault();
	    console.log(imgObj);
	    let gallery_data = new FormData();

	    if (imgObj.image1 != null) {
	    gallery_data.append('image_1', imgObj.image1);
	    }
	    if (imgObj.description1 != null) { 
	    gallery_data.append('description_1', imgObj.description1);
	    }	 	    
	    if (imgObj.image2 != null) {
	    gallery_data.append('image_2', imgObj.image2);
	   
	    }
	    if (imgObj.description2 != null) { 
	    gallery_data.append('description_2', imgObj.description2);
	    }	
	    if (imgObj.image3 != null) {
	    gallery_data.append('image_3', imgObj.image3);
	    
	    }
	    if (imgObj.description3 != null) { 
	    gallery_data.append('description_3', imgObj.description3);
	    }	
	    if (imgObj.image4 != null) {
	    gallery_data.append('image_4', imgObj.image4);
	    }
	    if (imgObj.description4 != null) { 
	    gallery_data.append('description_4', imgObj.description4);
	    }	
	    if (imgObj.image5 != null) {
	    gallery_data.append('image_5', imgObj.image5);
	    }
	    if (imgObj.description5 != null) { 
	    gallery_data.append('description_5', imgObj.description5);
	    }	
	    if (imgObj.image6 != null) {
	    gallery_data.append('image_6', imgObj.image6);
	    }
	    if (imgObj.description6 != null) { 
	    gallery_data.append('description_6', imgObj.description6);
	    }	
	    if (imgObj.image7 != null) {
	    gallery_data.append('image_7', imgObj.image7);
	    }
	    if (imgObj.description7 != null) { 
	    gallery_data.append('description_7', imgObj.description7);
	    }	
	    if (imgObj.image8 != null) {
	    gallery_data.append('image_8', imgObj.image8);
	    }
	    if (imgObj.description8 != null) { 
	    gallery_data.append('description_8', imgObj.description8);
	    }	
	    if (imgObj.image9 != null) {
	    gallery_data.append('image_9', imgObj.image9);
	    }
	    if (imgObj.description9 != null) { 
	    gallery_data.append('description_9', imgObj.description9);
	    }	
	    if (imgObj.image10 != null) {
	    gallery_data.append('image_10', imgObj.image10);
	    }
	    if (imgObj.description10 != null) { 
	    gallery_data.append('description_10', imgObj.description10);
	    }	
	    
	    

	    const token = localStorage.getItem(ACCESS_TOKEN_NAME);

	    loadingSet(true)


	    axios({
            method: "post",
            url: BASE_URL + "users/image/portfolio",
            data: gallery_data,
            headers: {
	        'Authorization': 'Bearer '+ token,
	        'Content-Type': 'multipart/form-data'
	      }
	    })
	        .then(res => {
	          //console.log(res.data);
	          updateErrorMessage("Information updated succesfully");
              severityUpdate("success");
              loadingSet(false)
	          //window.location.reload(false)
	        })
	        .catch(function (error) {

	        	    if(error.response.status!=415){
                    console.log(error);
                    updateErrorMessage("Information not updated. Please try again");
                    severityUpdate("warning");
                    loadingSet(false); 
                }

                    if(error.response.status===415){
                    	
                    updateErrorMessage("Information not updated. File extension not supported");
                    severityUpdate("warning");
                    loadingSet(false); 
                 }
                    
                }); 
	  };



	   const handleDelete = (e) => { 

	   		const id = e.target.id

	   		console.log(id)

		   	const token = localStorage.getItem(ACCESS_TOKEN_NAME);

		    axios({
	            method: "post",
	            url: BASE_URL + "users/delete/portfolio/images",
	            data: {[id]: 'delete'},
	            headers: {
		        'Authorization': 'Bearer '+ token,
		        'Content-Type': 'application/json'
		      }
		    })
		        .then(res => {

		          updateErrorMessage("Information deleted succesfully");
                  severityUpdate("success");	
                  
		         window.location.reload(false)
		          
		        })
		        .catch(function (error) {
                    console.log(error);
                    updateErrorMessage("Information not updated. Please try again");
                    severityUpdate("warning"); 
                    
                }); 

	  };


  

  return (


        <div className = "SettingsPersonalPage">

            

       
          <h1 className = 'SettingsPortfolioMainTitle'> Settings </h1>
          <p className = "SettingsPersonalHeaders"> {userType == 'artist' ? 'PORTFOLIO' : 'COLLECTION'} </p>
          <hr className = "SettingsPersonalLine"/>

          <div style ={{ display: loading ? 'flex' : 'none', justifyContent: 'center', fontWeight: 'bold' }}> <CircularProgress /> LOADING </div>

          <p className = 'SettingsPortfolioIntro'> 
          	Add pictures to your {userType == 'artist' ? 'portfolio' : 'collection'} here! Insert all information so your pictures will appear in more searches! In the 'tags' section
          	you can add keywords that are relevant to the picture, for example 'red', 'abstract' etc. this will increase your visibility when people searches for artworks

          </p>

          <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity={severity}/>


          
		  <div className = "SettingsPersonalSubmitButton">
              <Button variant="outlined"  onClick={handleSubmit}>Save changes</Button>
          </div> 

          <div>
	          <form>

	                 { 

	                   index.map((currentIndex, i) => (

			              <PortfolioEntry

			                index = {currentIndex} 
			                URLimage = {imgURL['URLimage'+currentIndex]}
			                URLdescription = {imgURL['URLdescription'+currentIndex]}  
			                handleImageChange = {handleImageChange} 
			                handleDelete = {handleDelete} 
			                handleChange = {handleChange} 
			                description = {imgObj['description'+currentIndex]}
			                descriptId = {'description'+ currentIndex}

			                setImgObj = {setImgObj}
			                imgObj = {imgObj}

			                />
			                ))
	                  }
	    
	            </form>
            </div>

            <div className = "SettingsPersonalSubmitButton">
               <Button variant="outlined"  onClick={handleSubmit}>Save changes</Button>
            </div> 

            <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity={severity}/>

        </div>


  )
}

export default SettingsPortfolio;
