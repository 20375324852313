
import React from 'react';
import { GoogleLogout } from 'react-google-login';
import {  Button } from '@material-ui/core';
import {BASE_URL, ACCESS_TOKEN_NAME, USER_ID, ACCESS_TICKET_NAME, HOME} from '../constants/apiConstants'


import axios from 'axios';

const clientId =
  '703599566697-gb94cmabbt3q56mnkcud2i24f86vi5fd.apps.googleusercontent.com';

function LogoutGoogle(props) {


  
  const ticket = localStorage.getItem(ACCESS_TICKET_NAME);
  const token = localStorage.getItem(ACCESS_TOKEN_NAME);
  
  
  
  const onSuccess = () => {
    

    localStorage.removeItem(ACCESS_TOKEN_NAME)
    localStorage.removeItem(USER_ID)
    localStorage.removeItem(ACCESS_TICKET_NAME)

    //console.log('you are out');

    let path = HOME; 
    window.location = path;

    props.chatLogout()
    
    axios.get(BASE_URL + 'oauth/logout/'+ ticket, {
          headers: {
            'Authorization': 'Bearer '+ token,
          }
        })
    .then((res) => {
            console.log(res)
        })
   

  };

   const handleLogout = () => {

       localStorage.removeItem(ACCESS_TOKEN_NAME)
        localStorage.removeItem(USER_ID)
        localStorage.removeItem(ACCESS_TICKET_NAME)

        

        let path = HOME
        window.location = path;

        props.chatLogout()
        
        axios.get(BASE_URL + 'oauth/logout/'+ ticket, {
              headers: {
                'Authorization': 'Bearer '+ token,
              }
            })
        .then((res) => {
                console.log(res)
            })

   }

   const handleLoginRoute = () => {

     let path = HOME+'loginhome'
     window.location = path;

   }


if(token){


      return (

         

        <div>

            <div >

                <GoogleLogout
                  

                    clientId={clientId}

                     render={renderProps => (
                        <Button onClick={renderProps.onClick} /*disabled={renderProps.disabled}*/ > Logout </Button>
                    )}

                    buttonText="Logout"
                    onLogoutSuccess={onSuccess}
                  >
                </GoogleLogout>

              </div>

           {/* <Button onClick = {handleLogout}> Logout </Button> */}
      
        </div> 
      );
  }

if(!token){

  return(

    <div>
      <Button onClick = {handleLoginRoute}> LOGIN/REGISTER</Button>
    </div>

    )

   }
}

export default LogoutGoogle;