
import React from 'react';
import { GoogleLogin } from 'react-google-login';
import {BASE_URL, ACCESS_TOKEN_NAME, ACCESS_TICKET_NAME, USER_ID, USER_TYPE} from '../constants/apiConstants'
import { useHistory } from "react-router-dom";
import axios from 'axios';
const clientId =
  '703599566697-gb94cmabbt3q56mnkcud2i24f86vi5fd.apps.googleusercontent.com';






function LoginGoogle(props) {


    const onSuccess = (res) => {

    

     if(res.profileObj && res.tokenObj) {

            //console.log(res.tokenObj) 
            const payload = res.tokenObj;
           

            axios({
            method: "post",
            url: BASE_URL + "oauth/google/login",
            data: payload,
            headers: {
            'Content-Type': 'text/plain;charset=utf-8'

            }
        })
                .then(function (response) {
                    if(response.status === 200){

                        localStorage.setItem(ACCESS_TOKEN_NAME,response.data.access_token);
                        localStorage.setItem(ACCESS_TICKET_NAME,response.data.ticket);
                        localStorage.setItem(USER_ID,response.data.user_id);
                        localStorage.setItem(USER_TYPE,response.data.user_type);
                        redirectToHome();
                        
                    } 

                    if(response.status === 206){

                        console.log('response: 206' + res.profileObj.email)

                        props.screenSet(2)
                        props.emailSet(res.profileObj.email)

                        {/*}

                        axios({
                                  method: "post",
                                  url: "http://34.219.113.18:8008/oauth/google/register",
                                  data: {user_type: '',
                                         email: res.profileObj.email},
                                  headers: {
                                  'Content-Type': 'text/plain;charset=utf-8'

                                  }
                              })  

                              .then(function (response) {
                                  
                                  if(response.status === 201){

                                      localStorage.setItem(ACCESS_TOKEN_NAME,response.data.access_token);
                                      localStorage.setItem(ACCESS_TICKET_NAME,response.data.ticket);
                                      localStorage.setItem(USER_ID,response.data.user_id);
                                      localStorage.setItem(USER_TYPE,response.data.user_type);
                                      redirectToHome();
                        
                                    }
                                  })

                              .catch(function (error) {
                    
                });  

                */}       
                        
                    } 



                    else{
                         
                    }
                })
                .catch(function (error) {
                    
                });    
        } else {
              
        }
    


  };




   const redirectToHome = () => {
        let path = `./`; 
        history.push(path);
    }

  const history = useHistory();


  const onFailure = (res) => {
    console.log('Login failed: res:', res);
    alert(
      `Failed to login`
    );
  };

  return (
    <div>
      <GoogleLogin


        clientId={clientId}

        buttonText="LOGIN WITH GOOGLE"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        style={{ marginTop: '100px' }}
        isSignedIn={true}
      />
    </div>
  );
}

export default LoginGoogle;

