import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import axios from 'axios';
import { Button } from '@material-ui/core';
import {BASE_URL, ACCESS_TOKEN_NAME} from '../constants/apiConstants'
import "./ReactCrop.css";

// Increase pixel density for crop preview quality on retina screens.
const pixelRatio = window.devicePixelRatio || 1;

// We resize the canvas down when saving on retina devices otherwise the image
// will be double or triple the preview size.
function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
}

function generateDownload(previewCanvas, crop, props) {
  if (!crop || !previewCanvas) {
    return;
  }

  const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);
  
  

  const token = localStorage.getItem(ACCESS_TOKEN_NAME);
 
 
  canvas.toBlob(
    (blob) => {

        let form_data = new FormData();
        form_data.append('image', blob);
        console.log(blob)

         axios({
            method: "post",
            url: BASE_URL + "users/image/profile",
            data: form_data,
            headers: {

            'Authorization': 'Bearer '+ token,  
            'Content-Type': 'application/json'

            }
        })
                .then(function (response) {
                    

                        window.location.reload(false)
                        console.log(response)
                        
                        
                   
                })
                .catch(function (error) {
                    
                    if(error.response.status===415){
                    
                    props.errorMessage('file extension not supported')
                    props.severityUpdate('warning')
                 }
                    
                });   
    },
    "image/png",
    1
  ); 


}

export default function CutImage(props) {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 10 / 10 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  return (
    <div className="App">

      <div>
         <input type="file" accept="image/*" onChange={onSelectFile} id="raised-button-file" style={{display: "none"}}/> 

         <label htmlFor="raised-button-file">
          <Button variant="outlined" component="span">
            choose picture
          </Button>
         </label>

    

        <Button
          variant="outlined"
          disabled={!completedCrop?.width || !completedCrop?.height}
          onClick={() => {
          generateDownload(previewCanvasRef.current, completedCrop);

          }
          }
        >
          upload picture
        </Button>

  </div>

      <div className = 'SettingPersonalCropWrap'>

      <ReactCrop
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
      />
      <div >

        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          className = 'SettingPersonalCropCanvas'
        />
      </div>

      </div>
      
     
    </div>
  );
}
