import React from 'react';
import { Link } from "react-router-dom";
import './Home.css'
import Avatar from '@material-ui/core/Avatar';


function HomeCards(props) {

  



  if(props.image.search_type==='IMAGE'){

  const  title = props.image.description.split(';')[0]
  const author = props.image.description.split(';')[1]
  const matter = props.image.description.split(';')[2]
  const dimensions = props.image.description.split(';')[3]

  return (

    <div className = 'imageContainer'>

      

          
          <img src= {props.image.image_url} alt="image" className="image"/>
     
      
     <div className = 'overlay'> 

       
       
       <Link to= {props.image.user_id} target="_blank" className = 'overlayColor'>

       <div className = 'overlayTopCard'>
         <p className = 'overlayName'> {props.image.first_name} {props.image.last_name}</p>
         <p className = 'overlayUserType'> {props.image.user_type} </p>
       </div>

       <div className = 'overlayBottomCard'>
         
        <div className = 'overlayBottomCardTop'>  

         <p className = 'overlayTitleHeader'> title:  </p>
         <p className = 'overlayTitle'> {title}</p>
         <p className = 'overlayAuthorHeader'> author:  </p>
         <p className = 'overlayAuthor'> {author}</p>
        </div>

        <div className = 'overlayBottomCardBottom'>
         <p className = 'overlayDescription'> {matter}, {dimensions} inch </p>
        </div>
         
       </div>

       </Link>
       
       
       
  
    </div>

    </div>
  );
 }

 else{

  return (

    <Link to= {props.image.user_id} target="_blank" className = 'nameCardContainer'>  

        <img src= {props.image.image_url} alt="image" className = 'nameCardImg'/>

        <div>

          <p className = 'nameCardName'> {props.image.first_name} {props.image.last_name}</p>
          <p className = 'nameCardUser_type'> {props.image.user_type == 'artist' ? 'ARTIST' : 'COLLECTOR'} - {props.image.city} </p>

        </div>

    </Link>

    )

 }

}

export default HomeCards; 