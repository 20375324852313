import React, {useState} from 'react'
import {  Container, Button, Input } from '@material-ui/core';
import Background from './cretto_burri.jpg';
import logoIcon from './Logo_logo.png';
import {PSWD_FLAG} from '../constants/apiConstants';
import './PasswordPage.css'




function PasswordPage(props){

	
  const[pswd, pswdSet] = useState('')

  const handlePassword = (e) => {

  	pswdSet(e.target.value)

  }

  const handleSubmitPassword = () => {

  	if(pswd == 'Malevich'){

  		props.locker(0)
  		localStorage.setItem(PSWD_FLAG,'Malevich');
  	}

  	
  }




	return(

		  <div className = 'backgroundPswd'>

		    <form>      
		      <div style = {{marginTop:'200px', display:'flex', justifyContent:'center'}}>

			        <Container maxWidth="md">

			          <div className = 'containerPswd'>
			            
			             <div className = 'insideContainerPswd'>   

			                <img className = 'logoPswd'  src={logoIcon}  alt="Logo_adam"/>

			                <div className = 'inputContainerPswd'>
			                  <h1 className = 'inputTitlePswd'> Insert password  </h1>	                  
			                  <Input type = 'password' value = {pswd} onChange = {handlePassword}/>
			                  <Button variant = 'outlined' onClick ={handleSubmitPassword} > SEND </Button>
			               </div>

			              </div>

			          </div>

			        </Container>
		      	</div>     

		      </form>

		  </div >


		)
}

export default PasswordPage