import React from 'react';
import './About.css';
import BottomBar from './BottomBar';
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';



function About(props) {

 const history = useHistory();
 

 const handleLink = (value) =>{

  history.push('/' + value)
 
 }


  return (

        <div >

          <div className='aboutMainContainer'>

            <h1 className='aboutMainTitle'> About </h1>

            <div className='aboutContent'>

                <p>  
                  <b style = {{fontWeight: 500 }}>artMecenate</b> is the only online platform 100% dedicated to art commissions and the only space hosting both artists and collectors 
                  profiles. artMecenate will guide you at every step of your commission; will produce contents to showcase  its commissioners, collectors, and artists;
                  will use the story of your commission to  help your business defining its identity and engage with your commuinty.
                </p>

                
                <div className = 'aboutSectionContainer' onClick = {()=> handleLink('howitworks')}>
                  <ArrowForwardIosIcon/> <h1 className = 'aboutSectionTitle'> HOW IT WORKS </h1>
                </div>
                <div className = 'aboutSectionContainer' onClick = {()=> handleLink('philosophy')}>
                  <ArrowForwardIosIcon/> <h1 className = 'aboutSectionTitle'> PHILOSOPHY </h1>
                </div>
                <div className = 'aboutSectionContainer' onClick={()=> handleLink('aboutpayments')}>
                  <ArrowForwardIosIcon/> <h1 className = 'aboutSectionTitle'> PAYMENTS, SHIPPING, ETC </h1>
                </div>
                <div className = 'aboutSectionContainer' onClick = {()=> handleLink('contactus')}>
                  <ArrowForwardIosIcon/> <h1 className = 'aboutSectionTitle'> CONTACT US </h1>
                </div>


            </div>
          </div>  

          <div className = 'bottomBarFixed'>
  	       <BottomBar />
  	      </div>

       </div >
  );
}

export default About;