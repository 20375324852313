import React, {useState} from 'react';
import './About.css';
import BottomBar from './BottomBar';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import { Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import AlertComponent from './AlertComponent';
import {BASE_URL} from '../constants/apiConstants'


function AboutContact(props) {


    const [ fullname, fullnameSet] = useState('')
    const [ email, emailSet] = useState('') 
    const [ portfolio, portfolioSet] = useState('') 
    const [ usertype, usertypeSet] = useState(null) 
    const [ message, messageSet] = useState('') 
  
    const [errorMessage, updateErrorMessage] = useState(null);
    const [severity, severityUpdate] = useState("success");

    
  
    const handleName = (e) => {
  
          fullnameSet(e.target.value)
    } 
  
    const handleEmail = (e) => {
  
          emailSet(e.target.value)
    }
  
    const handlePortfolio = (e) => {
  
          portfolioSet(e.target.value)
    }
  
    const handleUsertype = (e, value) => {
  
          usertypeSet(value)
    }
  
    const handleMessage = (e) => {
  
          messageSet(e.target.value)
    }
  
    const handleInquiry = (e) => { 
  
          axios({
                method: "post",
                url: BASE_URL + "oauth/registration/request",
              data: {
                full_name: fullname,
                email: email,
                instagram: 'contact us message',
                user_type: 'contact us message',
                message: message
            },
                headers: {
              'Content-Type': 'application/json'
            }
          })
              .then(res => {
  
                if(res.status === 201){
  
                          updateErrorMessage("The request was sent! Click anywhere to close the window ");
                          severityUpdate("success")}
                  
               
                
              })
              .catch(function (error) {
                      console.log(error);
                      updateErrorMessage("An error occured. Please try again. If the problem persists please contact the support team");
                      severityUpdate("warning"); 
                      
                  });
         
      }
  

  return (

        <div >

          <div className='aboutMainContainer'>

            <h1 className='aboutMainTitle'> CONTACT US </h1>

            <div className='aboutContent'>

                
                {/* 
                <p className='aboutContactEntryContainer'>
                    <LocationOnIcon/> 
                    <p className='aboutContactEntrytext' > 302 California street, 94102, San Francisco, California, United States of America </p>
                </p>
                */}

                <p className='aboutContactEntryContainer'>
                    <EmailIcon/> 
                    <a href = ' mailto:artmecenate@gmail.com' className='aboutContactEntrytext' > artmecenate@gmail.com </a>
                </p>

                <p className='aboutContactFormHeader'> You can use the contact form below for any inquiry, question, or communication. We'll get back to you soon.</p>
                <TextField className = 'RegistrationFormEntries' label = 'Full name' value = {fullname} onChange = {handleName} />
                <TextField className = 'RegistrationFormEntries' label = 'E-mail' value = {email} onChange = {handleEmail} />

                <div className = 'RegistrationFormMessage'>
                <TextField className = 'RegistrationFormMessage'
                           value={message}
                           onChange = {handleMessage}
                           variant = 'outlined' 
                           multiline 
                           rows = {4} 
                           inputProps={{ maxLength: 500 }} 
                           placeholder = 'Write your message(max 500 characters)'/>
                </div>

                <div className = 'RegistrationFormButton'>
                  <Button onClick = {handleInquiry} variant = 'outlined' > SUBMIT </Button>
                </div>

                <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity={severity}/>


            </div>
          </div>  

          <div className = 'bottomBarFixed'>
  	       <BottomBar />
  	      </div>

       </div >
  );
}

export default AboutContact;