import React, {useState} from 'react';
import Background from './cretto_burri.jpg';
import logoIcon from './Logo_logo.png';
import {  Container, Button, Input } from '@material-ui/core';
import { useHistory } from "react-router-dom";


function Password() {

  const [pswd, pswdSet] = useState('');	
  const history = useHistory();

  const handleSubmitPassword = () =>{ 
    if(pswd == 'Malevich'){

      
      history.push('/')
    }
  }

  const handlePassword = (e) =>{ 
    pswdSet(e.target.value)
  }

  return (


    <div style={{display:'flex', justifyContent:'center', backgroundImage: "url(" + Background + ")", maxWidth:'100vw',height:'100vh', backgroundSize: 'cover' }}>

    <form>
        
      <div style = {{marginTop:'200px', display:'flex', justifyContent:'center'}}>

        <Container maxWidth="md">

          <div style = {{ backgroundColor: 'rgba(255, 255, 255, 0.7)'}}>
            
             <div style={{display:'flex', flexDirection:'column', padding: '30px', width:'350px'}}>   

                <img style = {{width: "180px", marginLeft:'auto',marginRight:'auto'}}  src={logoIcon}  alt="Logo_adam"/>

                <div style = {{ backgroundColor: 'rgba(255, 255, 255, 0.6)', padding: '10px'}}>

                  <h1 style = {{fontVariant: 'small-caps', color: 'black', fontSize: '30px' }}> Insert password  </h1>
                  
                  

                  <Input type = 'password' value = {pswd} onChange = {handlePassword}/>
                  <Button variant = 'outlined' onClick ={handleSubmitPassword} > SEND </Button>

                
                  
                
              
                  
               </div>

              </div>

          </div>

        </Container>



      </div>

            
      </form>


       </div >
  )

}

export default Password