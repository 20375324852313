import React from 'react';

const ErrorPage = () => {

    return (

       <div style={{border: '1px solid lightgrey', width: '25%', margin: '20px', padding:'10px' }}>
          <h1>Error: Page not found!</h1>
       </div>
    );
}

export default ErrorPage;
