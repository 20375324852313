import React from 'react';
import klevraFigure from './Klevra_figure.png';
import elviraFigure from './Elvira_figure.jpeg';
import maupalFigure from './super_pope.jpeg';
import umanFigure from './UMAN_figure.jpeg';
import HomeShortcutsCards from './HomeShortcutsCards';
import PictureGridCards from './PictureGridCards';
import './PictureGridCards.css'


function PictureGrid(props) {

  

 const PictureGridCont = {

      display: 'grid',
      gridAutoColumns: props.unitColumn,
      gridAutoRows: props.unitRow,
      
    }

  return (

  <div style = {PictureGridCont}>     
    {props.children}
  </div>

    )  


}

export default PictureGrid; 