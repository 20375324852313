import React from 'react';
import BottomBar from './BottomBar';
import './About.css';




function AboutPayments(props) {


  return (

        <div>

          <div className='aboutMainContainer'>

            <h1 className='aboutMainTitle'> PAYMENTS, SHIPPING, ETC. </h1>



            <div className = 'aboutHowItWorksContent'>

            <ul className='aboutHowItWorksList'>
              <li className='aboutHowItWorksListitem'> Price includes step-by-step guidance, shipping, handling, canvas strectching if needed, certificate of authenticity, additional documentation, and contracts </li>
              <li className='aboutHowItWorksListitem'> No additional fees </li>
              <li className='aboutHowItWorksListitem'> Taxes are not included and will be added at the time of the payment </li>
              <li className='aboutHowItWorksListitem'> Payments will be processed on-line using a secure, state-of-the-art method. artMecenare will share with you a secure link at the time of the payment  </li>
              <li className='aboutHowItWorksListitem'> Payments will follow the 1/3:1/3:1/3 rule: the comissioner will pay 1/3 of the cost upon online contract signature, 1/3 after the first revision, 1/3 at the completion of the work  </li> 
              <li className='aboutHowItWorksListitem'> The commissioner can interrupt the commission for any reason before the first revision at no cost and get all money back. Interruption upon or after first revision
                                                       will cost 33% of the entire commission price </li>
              <li className='aboutHowItWorksListitem'> The commissioner can interrupt the commission fat any time at no cost if the artist doesn't respect the timeline so as (if) defined in the commission contract  </li>                                         
              <li className='aboutHowItWorksListitem'> artMecenate ships nationwide (USA) and internationally using trackable fast shipping courirers (FedEx or UPS) </li>
              <li className='aboutHowItWorksListitem'> All artworks will be professionally prepared for shipping </li>
            </ul>
              
            </div>
       
          </div>  

          <div className = 'bottomBarFixed'>
  	       <BottomBar />
  	      </div>

       </div >
  );
}

export default AboutPayments;