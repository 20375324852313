import React, {useState} from 'react';
import './About.css';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import BottomBar from './BottomBar';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ReactPlayer from 'react-player'



function AboutHowItWorks(props) {

  const [selection, setSelection] = useState('everyone')

  const handleSelection = (event, newFormats) => {
    setSelection(newFormats);
  };



  const StyledToggleButton = withStyles((theme) => ({

  root: {

    "&.Mui-selected": {
            color: 'black'
          },

    border: '0.5px',
    borderStyle: 'solid',
    borderColor: 'grey',
    marginTop: '5px',
    color: 'grey',
    height: 48, 
    fontWeight: '400',
    
    
  },

}))(ToggleButton);

let body

if(selection == 'everyone')
 body = (       
          <div className='aboutHowItWorksContent'>
            <div className='aboutHowItWorksPlayerWrapper'>
              <ReactPlayer volume = {.1} controls = {true} url = 'https://www.youtube.com/watch?v=YnKTgtVBqBA&list=PLZJfjhLxnylq8Q76m43wen5RVUVRp9Sd5' className='aboutHowItWorksPlayer' width = '100%' height = '100%'/> 
            </div>
            <ul className='aboutHowItWorksList'>
              <li className='aboutHowItWorksListitem'> Explore Artists and Collectors' profiles </li>
              <li className='aboutHowItWorksListitem'> You don't need to open a profile to start exploring and buying your commissions </li>
              <li className='aboutHowItWorksListitem'> All artists are selected professionals, they become part of the artMecenate commuinty excusively by invitation </li>
              <li className='aboutHowItWorksListitem'> You can use a link from the homepage</li>
              <li className='aboutHowItWorksListitem'> You can search for pictures using our search bar (example: 'portrait', or 'abstract in red') </li>
              <li className='aboutHowItWorksListitem'> Use the filters to narrow your search if needed (select a city, country, a style, or a price range) </li>
              <li className='aboutHowItWorksListitem'> You can even just type the artist's name if you are looking for someone in particular</li>
              <li className='aboutHowItWorksListitem'> See your price immediately based on the dimensions and the kind of artpiece you are interested in (example: oil on canvas, ...)</li>
              <li className='aboutHowItWorksListitem'> Submit your requests, an artMecenate representative will contact you soon </li>
              <li className='aboutHowItWorksListitem'> Price includes shipping (national or international), step by step personal assistance through your commission, direct contact with the artists, certificates of authenticity, and legal documentation </li>
              <li className='aboutHowItWorksListitem'> Choose your masterpiece we'll do the rest!</li>
              <li className='aboutHowItWorksListitem'> If you like, artMecenate will be happy to advertise your commission on their social media channel through pictures, videos, and even interviews. You are doing something beautiful, let the world know! </li>
            </ul>
          </div>     
         )




if(selection == 'collectors')
  body = (       
          <div className='aboutHowItWorksContent'>
        
            <ul className='aboutHowItWorksList'>
              <li className='aboutHowItWorksListitem'> Open your profile page on artMecenate <a style ={{color: 'rgb(220,0,0)'}} href = '/registrationform'> here </a>  and: </li>
              <ul style ={{marginTop:'10px'}}>
                <li> Share your collection with everyone </li>
                <li> Tell us about yourself and your passion for art and collecting </li>
                <li> Produce something meaningful for yourself and for everyone </li>
                <li> Be feautured (if you like) in our social media stories and magMecenate articles </li>
                <li> Follow and receive real time notifications from all of our artists and collectors </li>
                <li> Chat directly with all the other collectors and artists members</li>
              </ul>

            </ul>
          </div>     
          )

if(selection == 'artists')
  body = (       

          <div className='aboutHowItWorksContent'>
        
            <ul className='aboutHowItWorksList'>
              <li className='aboutHowItWorksListitem'> Open your profile page on artMecenate <a style ={{color: 'rgb(220,0,0)'}} href = '/registrationform'> here </a>  and: </li>
              <ul style ={{marginTop:'10px'}}>
                <li> Share your portfolio with everyone </li>
                <li> Tell us about yourself, your artistic journey, your vision </li>
                <li> Earn money by doing something meaningful for yourself and for everyone </li>
                <li> Get recognized for your work </li>
                <li> Be feautured (if you like) in our social media stories and magMecenate articles </li>
                <li> Be part of a community of selected artists and collectors </li>
                <li> Chat directly with all the other collector and artist members</li>
              </ul> 

            </ul>
          </div>     
          )

  return (

        <div>

          <div className='aboutMainContainer'>

            <h1 className='aboutMainTitle'> How it works </h1>

            <div className='aboutHowItWorksSelector'>
              <ToggleButtonGroup exclusive value={selection} onChange={handleSelection} >
                <StyledToggleButton value="everyone" aria-label="bold"  >
                  For everyone
                </StyledToggleButton>
                <StyledToggleButton value="collectors" aria-label="italic" >
                  for Collectors
                </StyledToggleButton>
                <StyledToggleButton value="artists" aria-label="underlined" >
                  for Artists
                </StyledToggleButton>
              </ToggleButtonGroup>
            </div>

            <div className = 'aboutHowItWorksContent'>
              {body}
            </div>
       
          </div>  

          <div className = 'bottomBarFixed'>
  	       <BottomBar />
  	      </div>

       </div >
  );
}

export default AboutHowItWorks;