
import { useEffect, useRef, useState } from "react";
import {ACCESS_TICKET_NAME, BASE_URL_WS  } from '../constants/apiConstants';


const ChatEngine = (props) => {

    const wsRef = useRef();
    const ticket = localStorage.getItem(ACCESS_TICKET_NAME);

    
    

    const [inbox, setInbox] = useState([])

    const [conversations, setConversations] = useState([])

    const [unread, setUnread] = useState(0)


    // instance of websocket connection as a class property

    const [connected, setConnected] = useState(false)
    
    


    useEffect(() => {

        

        if(!connected){

            wsRef.current = new WebSocket(BASE_URL_WS + 'ws/connect/'+ticket)

           

              wsRef.current.onopen = () => {
                    // on connecting, do nothing but log it to the console
                          console.log('connected to chat')
                     }

            setConnected(true)
    }
        

            wsRef.current.onmessage = evt => {
            // listen to data sent from the websocket server
                  
            const data_in = JSON.parse(evt.data)

            if (data_in.message){

                setInbox(inbox => ([ ...inbox, data_in ]));
                
            }
            
            else {
            setConversations(conversations => ([ {}, data_in  ]))

            console.log(data_in)
            var counter = 0

            const mapping = data_in.map((obj, i) => (

                    counter = (counter + obj.unread)    
            
            ))


            }
            
            //setConversations(data_in);
            setUnread(counter)

            //console.log(conversations[1])
        }
        


        wsRef.current.onclose = () => {
        console.log('disconnected')

        if (ticket){
        setConnected(false)}
        
            //setConnected(true)

        }

        
       
    }, [inbox, conversations, connected]);


       const sendMessage=(message)=>{
        
            try {
                wsRef.current.send(JSON.stringify(message)) //send data to the server
            } catch (error) {
                console.log(error) // catch error
            }}


       const closeChat=()=>{

             wsRef.current.close = () => {
                    // on connecting, do nothing but log it to the console
                          console.log('disconnected to chat')
                     }
                 }

    


    return{inbox, conversations, sendMessage, closeChat, unread}
}

export default ChatEngine



