import React, {useState} from 'react';
import {  Container, Button, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useHistory } from "react-router-dom";
import logoIcon from './logo_fontana.png';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Background from './cretto_burri.jpg';
import {BASE_URL, ACCESS_TOKEN_NAME, ACCESS_TICKET_NAME, USER_ID, USER_TYPE, HOME} from '../constants/apiConstants'


import AlertComponent from './AlertComponent';

import './LoginHome.css'

const ButtonStyles = makeStyles({


  register: {
    background: 'white',
    border: 10,
    borderColor: 'black',
    borderRadius: 83,
    marginTop: '10px',
    color: 'black',
    padding: '0 30px',
    fontSize: '15px',
    height: 28,
  },

  back: {
    background: 'white',
    border: 10,
    borderColor: 'black',
    borderRadius: 83,
    
    color: 'black',
    height: 28,
    padding: '0 15px',
    fontSize: '12px',
  },

});





function Register(props) {


  const [state , setState] = useState({
        email : "",
        password : "",
        confirmPassword: "",
        successMessage: null
    })


    const [errorMessage, updateErrorMessage] = useState(null);

    const [severity, severitySet] = useState('error')

    const [user_type, user_typeSet] = useState(null);

    const [display, displaySet] = useState('input')

    const [access, accessSet] = useState(false)
    const [accessPssw, accessPsswSet] = useState('')


    
    

    const handleAccessPassword = (e) =>{

         accessPsswSet(e.target.value)
    }

    const handleAccessPasswordVerification = () =>{

        if(accessPssw == 'AM_vs1274wt'){

          accessSet(true)
        }
        else{
          updateErrorMessage('The inserted password is wrong') 
        }
    }


    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }


    const sendDetailsToServer = () => {

        if(state.email.length && state.password.length && user_type) {
            updateErrorMessage(null);
            const payload={
                "email":state.email,
                "user_type": user_type,
                "password":state.password,
                "auth_type": "password",
            }
            axios({
            method: "post",
            url: BASE_URL + "users/create",
            data: payload,
            headers: {
            'Content-Type': 'text/plain;charset=utf-8'

            }
        })
                .then(res=> {

                    displaySet('verification')

                    if(res.status === 201){

                                displaySet('verification')
                                

                            {/*

                            localStorage.setItem(ACCESS_TOKEN_NAME,response.data.access_token);
                            localStorage.setItem(ACCESS_TICKET_NAME,response.data.ticket);
                            localStorage.setItem(USER_ID,response.data.user_id);
                            localStorage.setItem(USER_TYPE,response.data.user_type);
                            redirectToHome();
                            updateErrorMessage(null)

                          */}
                    } 
  

                })

                .catch(function (error) {

                      updateErrorMessage("Registration error. The user might be already registered");

                    
                });    

        } else {
            updateErrorMessage('Please enter valid username, password and user type')    
        }
        
    }


    const history = useHistory();

    const redirectToHome = () => {
        window.location = HOME
    }

    const redirectToLogin = () => {
        let path = `./LoginHome`; 
        history.push(path);
    }
    
    const handleSubmitClick = (e) => {
        e.preventDefault();
        if(state.password === state.confirmPassword) {
            sendDetailsToServer()    
        } else {
            updateErrorMessage('Passwords do not match');
        }
    }

   const classes = ButtonStyles();


   const handleUser_type = (e, value) => {

    
    user_typeSet(value)

    console.log(value)
    

  }

if(access){
  return (


    <div className = 'background'>

        <form >

         <div className = 'preContainer'>

            <Container  maxWidth="md">

              
                
                 <div class = 'containerContent'>


                    <img className = 'logo'  src={logoIcon}  alt="Logo_adam"/>

                    <div style={{display: display != 'input' ? '':'none', margin:'15px', backgroundColor: 'rgb(217, 234, 212)', color:'rgb(9, 86, 29)', padding:'10px', textAlign:'justify', fontWeight:'500'}}>

                      Almost there! An email was sent to the address you used to register with a link to verify your account. Please verify your account to join Mecenate!

                    </div>

                    <div style={{display: display == 'input' ? '':'none'}}>

                    <div className = 'inputsContainer' >



                    
                    
                    <Input type="email" 
                           
                           id="email" 
                           aria-describedby="emailHelp" 
                           placeholder="Enter email" 
                           value={state.email}
                           onChange={handleChange}
                           style={{ backgroundColor:'rgba(255, 255,255,0.7)', borderRadius:'2px'}}
                    />
                    
                  
                    
                        
                        <Input type="password" 
                            
                            id="password" 
                            placeholder="Password"
                            value={state.password}
                            onChange={handleChange} 
                            style={{ backgroundColor:'rgba(255, 255,255,0.7)', borderRadius:'2px'}}
                        />
                    
                        
                        <Input type="password" 
                             
                            id="confirmPassword" 
                            placeholder="Confirm Password"
                            value={state.confirmPassword}
                            onChange={handleChange} 
                            style={{ backgroundColor:'rgba(255, 255,255,0.7)', borderRadius:'2px'}}
                        />

                     <Autocomplete

                      onChange={handleUser_type}
                       value={user_type}
                      style={{ backgroundColor:'rgba(255, 255,255,0.7)', borderRadius:'2px'}}
                      options={['artist','commissioner']}
                      renderInput={(params) => <TextField {...params} label="User type" />}
                    />
                    
                    <Button 
                        variant = "contained"
                        type="submit"
                        className={classes.register} 
                        onClick={handleSubmitClick}
                        
                    >
                        Sign in
                    </Button>

                  </div>  

                  </div>

                  <div style={{ marginBottom: '10px'}}>

                     <Button   
                        onClick = {redirectToLogin}
                        className = {classes.back} 
                        variant = "contained"
                        startIcon = {<ChevronLeftIcon/>}
                      >
                        LOGIN
                      </Button>

                  </div>

                  <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity = {severity} />

                </div>
              


            </Container>
     
          </div>

        </form>
    </div >
  )
 }

 if(!access){
  return (


    <div className = 'background'>

        <form >

         <div className = 'preContainer'>

            <Container  maxWidth="md">

              
                
                 <div class = 'containerContent'>


                    <img className = 'logo'  src={logoIcon}  alt="Logo_adam"/>

                    <div className = 'inputsContainer' >

                    <div style={{ textAlign: 'justify', fontWeight: '400'}}>
                    Insert your password if you have one already or submit your application form <a style ={{color: 'rgb(212,0,0)'}} href = '/registrationform'> here </a> 
                    </div>

                    <TextField label = 'Password' 
                               type = 'password' 
                               value={accessPssw}
                               onChange = {handleAccessPassword}
                               onKeyDown = {(e) => {
                                      if (e.key === 'Enter') {
                                          handleAccessPasswordVerification()    
                                                }
                                            }}> 
                    </TextField>
                    <Button onClick = {handleAccessPasswordVerification} variant = 'contained' className = {classes.back}> submit password </Button>



                  

                  </div>  

                  

                  <div style={{ marginBottom: '10px'}}>

                     <Button   
                        onClick = {redirectToLogin}
                        className = {classes.back} 
                        variant = "contained"
                        startIcon = {<ChevronLeftIcon/>}
                      >
                        LOGIN
                      </Button>

                  </div>

                  <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity = {severity} />

                </div>
              


            </Container>
     
          </div>

        </form>
    </div >
  )
 }
}

export default Register;
