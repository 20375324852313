import React, {useState} from 'react';
import {TextField, Input, Button } from '@material-ui/core';


import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import {BASE_URL, ACCESS_TOKEN_NAME, USER_ID} from '../constants/apiConstants'
import AlertComponent from './AlertComponent';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Slider from '@material-ui/core/Slider';

import freeFormPic from './Free_form.jpeg';
import portraitPic from './Portrait.jpeg';
import modelPic from './Model.jpeg';
import fullCustomPic from './Cappella-sistina-volta.jpeg';

import './Settings.css'



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const travelOptions = [
  'no travel',
  'statewide',
  'nationwide',
  'international', 
];

const styles = [
  'Abstract',
  'Metaphysics',
  'Figurative', 
  'Portraits']

const matters = [
  'oil',
  'tempera',
  'watercolor', 
  'acrylic']

  const mediums = [
  'canvas',
  'paper',
  'wood', 
  ]



function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}



function SettingsCommission(props) {


  const [errorMessage, updateErrorMessage] = useState(null);
  const [severity, severityUpdate] = useState("success");


  const [profileInfo, profileInfoUpdate] = useState({});

  const [info , setInfo] = useState({

        style : [],
        matter : [],
        medium : [],
        sizeW : "",
        sizeH : "",
        min_price : '',
        max_price: '',
        price: [],
        travel: 'no travel',        
    })
 
   const [freeForm, freeFormSet] = useState(false)
   const [portrait, portraitSet] = useState(false)    
   const [model, modelSet] = useState(false)    
   const [fullCustom, fullCustomSet] = useState(false)         




React.useEffect(() => {
    
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const userID = localStorage.getItem(USER_ID);
        

        axios.get(BASE_URL + 'users/get/artist/'+userID, {
          headers: {
            'Authorization': 'Bearer '+ token,
          }
        })
        .then((res) => {
          
          profileInfoUpdate(res.data)

          console.log(res.data)

          var matter_list = []
          if(res.data.matter){
            matter_list = res.data.matter.split(';')
            matter_list.splice(matter_list.length-1,1)
          }

          var medium_list = []
          if(res.data.medium){
            medium_list = res.data.medium.split(';')
            medium_list.splice(medium_list.length-1,1)
          }

          var style_list = []
          if(res.data.style){
            style_list = res.data.style.split(';')
            style_list.splice(style_list.length-1,1)
          }

          var price_list = []
          if(res.data.price){
            price_list = res.data.price.split(';')
            price_list.splice(price_list.length-1,1)
          }


          setInfo(prevState => ({
            ...prevState,
            style : style_list,
            matter : matter_list,
            medium : medium_list,
            price : price_list,
            min_price : res.data.min_price,
            max_price: res.data.max_price,
            travel: res.data.travel, 

        }))

          freeFormSet(res.data.freeform)
          portraitSet(res.data.portrait)
          modelSet(res.data.model)
          fullCustomSet(res.data.fullcustom)

          

              
        })
        .catch((error) => {
          console.error(error)
        })

      
    

  }, []);





  const handleChange = (e) => {
        const {id , value} = e.target   
        setInfo(prevState => ({
            ...prevState,
            [id] : value
        }))
    }




const handleSubmitClick = (e) => {

        e.preventDefault();

        var stylesSummary = ''
        info.style.map((entry) => (stylesSummary = stylesSummary  + entry + ';'))

        var mattersSummary = ''
        info.matter.map((entry) => (mattersSummary = mattersSummary  + entry + ';'))

        var mediumsSummary = ''
        info.medium.map((entry) => (mediumsSummary = mediumsSummary  + entry + ';'))

        var priceSummary = ''
        info.price.map((entry) => (priceSummary = priceSummary  + entry + ';'))

        
        var size = Math.max(info.sizeW, info.sizeH)
                              

        const payloadInfo={


            "style":stylesSummary,
            "matter":mattersSummary,
            "medium":mediumsSummary,
            "price":priceSummary,
            "min_price":parseInt(info.min_price), 
            "max_price":parseInt(info.max_price),
            "size":size.toString(),
            "travel": info.travel,
            "freeform": freeForm,
            "portrait": portrait,
            "model": model,
            "fullcustom": fullCustom   

        }

        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        //console.log(payloadInfo)

        axios({
            method: "post",
            url: BASE_URL+'users/update/artist',
            data: payloadInfo,
            headers: {

            'Authorization': 'Bearer '+ token,  
            'Content-Type': 'application/json'

            }
        })
                .then(function (response) {
                    if(response.status === 200){

                        updateErrorMessage("Information updated succesfully");
                        severityUpdate("success");
                        
                        
                    } else{

                        updateErrorMessage("Information not updated. Please try again");
                        severityUpdate("success"); 
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    updateErrorMessage("Information not updated. Please try again");
                    severityUpdate("warning"); 
                    
                });    
         
           
    }

  

  /*  MATERIAL-UI STYLE  */

  const classes = useStyles();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState(['alvaro']);



  const handleChangeStyle = (e, value) => {
  
        setInfo(prevState => ({
            ...prevState,
            'style' : value.map(element => {
                                                  return element.toUpperCase();
                                                })
        }))
        
       
  };

  const handleChangeMatter = (e, value) => {
        
        console.log(value)
        setInfo(prevState => ({
            ...prevState,
            'matter' : value.map(element => {
                                                  return element.toUpperCase();
                                                })
        }))
  };

  const handleChangeMedium = (e, value) => {
  
        setInfo(prevState => ({
            ...prevState,
            'medium' : value.map(element => {
                                                  return element.toUpperCase();
                                                })
        }))
  };

  const handleChangeSizeW = (e) => {
  
        setInfo(prevState => ({
            ...prevState,
            'sizeW' : e.target.value
        }))
  };

  const handleChangeSizeH = (e) => {
  
        setInfo(prevState => ({
            ...prevState,
            'sizeH' : e.target.value
        }))
  };

  const handleChangeMinPrice = (e) => {
  
        setInfo(prevState => ({
            ...prevState,
            'min_price' : e.target.value
        }))
  };

    const handleChangeMaxPrice = (e) => {
  
        setInfo(prevState => ({
            ...prevState,
            'max_price' : e.target.value
        }))
  };

  const handleChangeTravel = (e) => {

        console.log(e.target.value)
        setInfo(prevState => ({
            ...prevState,
            'travel' : e.target.value
        }))
  };


 const handleChangePrice = (e) => {

         console.log(info.price)
         var array = info.price
         array[e.target.id] = e.target.value
         setInfo(prevState => ({
            ...prevState,
            'price' : array
        }))

  };



 
   const handleFreeForm = (e) => {

         freeFormSet(!freeForm)
  };

   const handlePortrait = (e) => {

        portraitSet(!portrait)
  };

     const handleModel= (e) => {

        modelSet(!model)
  };

     const handleFullCustom = (e) => {

        fullCustomSet(!fullCustom)
  };
 

 

  return (


        <div className = "SettingsPersonalPage">



          
         <p className = "SettingsPersonalHeaders"> COMMISSION SETTINGS </p>
         <hr className = "SettingsPersonalLine"/>



        {/*  #########     FORM   ###########         */}

          <form>

                <div  className = "SettingsCommissionItem">
        		  	      <p  className = 'SettingsCommissionItemName'> STYLE: </p>

                    <div className = "SettingsCommissionEntry">

                      <Autocomplete
                          multiple
                          id="tags-filled"
                          options={styles.map((option) => option)}
                          value={info.style}
                          defaultValue= {[]}
                          onChange={handleChangeStyle}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField {...params}  label="style" placeholder="write your own and press Enter"  />
                          )}
                      />
        	      	  </div>  
                     

                </div>

                <div  className = "SettingsCommissionItem">
                      <p className = 'SettingsCommissionItemName'> MATTERS: </p>
                        
                      <div className = "SettingsCommissionEntry" >
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          options={matters.map((option) => option)}
                          value={info.matter}
                          defaultValue= {[]}
                          onChange={handleChangeMatter}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField {...params}  label="matters" placeholder="write your own and press Enter"  />
                          )}
                         /> 
                     </div>        
                </div>

                 <div  className = "SettingsCommissionItem">
                      <p className = 'SettingsCommissionItemName'> MEDIUMS: </p>
                      <div className = "SettingsCommissionEntry" >
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          options={mediums.map((option) => option)}
                          value={info.medium}
                          defaultValue= {[]}
                          onChange={handleChangeMedium}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField {...params}  label="mediums" placeholder="write your own and press Enter"  />
                          )}
                         /> 
                     </div>                  
                </div>

                <div>

                 {info.medium.map((medium, index) => 

                   <div  className = "SettingsCommissionItem">
                    <p  className = 'SettingsCommissionPriceName'> PRICE {medium}: </p>

                    <div className= 'SettingsCommissionPriceEntryWrap'>
                      <Input className = "SettingsCommissionPriceEntry"  type= "number" placeholder="minimum price" id={index} value={info.price[index]} onChange={handleChangePrice}/>
                      
                      $/inch - The price of the artwork will be (width + height) x price
                    </div>
                           
                </div>


                  )}

                </div>


                <div  style={{display: "none"}}>
          		  	  <p className = 'SettingsCommissionItemName'> SIZE: </p>

                    <div className= 'SettingsCommissionSizeEntryWrap'>
            	      	<Input className= 'SettingsCommissionSizeEntry' type= "number" placeholder="maximum size " id="size" value={info.sizeW} onChange={handleChangeSizeW} />
                      x
                      <Input className= 'SettingsCommissionSizeEntry' type= "number" placeholder="of your artworks" id="size" value={info.sizeH} onChange={handleChangeSizeH} />
                      inch
                    </div>

                </div>

                <div  className = "SettingsCommissionItem">
                    <p className = 'SettingsCommissionItemName'> PRICE RANGE: </p>

                    <div className= 'SettingsCommissionPriceRangeEntryWrap'>
                      <Input className= 'SettingsCommissionPriceRangeEntry' type= "number" placeholder="minimum price" id="size" value={info.min_price} onChange={handleChangeMinPrice}/>
                      $
                      <p style = {{ marginLeft: '15px'}}> - </p>

                      <Input className= 'SettingsCommissionSizeEntry' type= "number" placeholder="maximum price" id="size" value={info.max_price} onChange={handleChangeMaxPrice} />
                      $
                    </div>
                           
                </div>

                <div  className = "SettingsCommissionItem">
                    <p className = 'SettingsCommissionItemName'> TRAVEL: </p>
                    
                      <form >
                        <div className= 'SettingsCommissionTravelEntry'>
                          <TextField
                            id="standard-select-travel"
                            select
                            value={info.travel}
                            onChange={handleChangeTravel}
  
                          >
                            {travelOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                          
                        </div>
                      </form>
                </div>

            </form>



            <div>
     

     
      
    </div>


    <p className = "SettingsPersonalHeaders"> COMMISSION MODES SETTINGS </p>
         <hr className = "SettingsPersonalLine"/>



         <div style = { {display: 'flex', flexDirection: 'column', fontVariant: 'small-caps'}}>

         <p1 style ={{ fontSize: '20px'}}> Select which types of commission you are willing to accept </p1>

        <div className = 'CommissionTypeContainer'> 
         
          <Checkbox
            className = 'commissionCheckbox'
            checked={freeForm}
            color = 'primary'
            onChange={handleFreeForm}

          />

         <div className = 'commissionCardContainer'>
            <img src = {freeFormPic} className = 'commissionCardPic'/>
            <div className = 'commissionCardText'>
              <h2> Free form</h2>
              <p> The commissioner only sets the  dimensions, the support (canvas, paper ...), and the matter (oil, graphite, ...), the artist will create something freely </p>
            </div>
          </div>

        </div>


        <div className = 'CommissionTypeContainer'> 
         
          <Checkbox
            className = 'commissionCheckbox'
            checked={portrait}
            color = 'primary'
            onChange={handlePortrait}

          />

            <div className = 'commissionCardContainer'>
              <img src = {portraitPic} className = 'commissionCardPic'/>
              <div className = 'commissionCardText'>
                <h2> portrait</h2>
                <p> A commission of a portrait.  </p>
              </div>
            </div>
          </div>  

          <div className = 'CommissionTypeContainer'> 
         
          <Checkbox
            className = 'commissionCheckbox'
            checked={model}
            color = 'primary'
            onChange={handleModel}

          />

            <div className = 'commissionCardContainer'>
              <img src = {modelPic} className = 'commissionCardPic'/>
              <div className = 'commissionCardText'>
                <h2> model</h2>
                <p> The commissioner chooses one of the artist's portfolio pictures as a model and ask for a similar commission. The commissioner can  personalize dimensions, colors, medium, and matter.  </p>
              </div>
            </div>
          </div>
          
          <div className = 'CommissionTypeContainer'> 
         
            <Checkbox
              className = 'commissionCheckbox'
              checked={fullCustom}
              color = 'primary'
              onChange={handleFullCustom}

            />  

            <div className = 'commissionCardContainer'>
              <img src = {fullCustomPic} className = 'commissionCardPic'/>
              <div className = 'commissionCardText'>
                <h2> full custom</h2>
                <p> The commissioner can customize the commission in all aspects.  </p>
              </div>
            </div>

           </div>
          </div> 





            <div className = "SettingsPersonalSubmitButton">
                  <Button variant="outlined"  onClick={handleSubmitClick}>Submit</Button>
            </div>


            <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity={severity}/>






        </div>


  )
}

export default SettingsCommission;
