import React from 'react';
import { useHistory } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import BrushIcon from '@material-ui/icons/Brush';
import ImageIcon from '@material-ui/icons/Image';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import ManageAccountsIcon from '@material-ui/icons/AccountCircle';


import {USER_TYPE, ACCESS_TOKEN_NAME} from '../constants/apiConstants'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    
     
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.black,
      
    },
  },
}))(MenuItem);

export default function SettingsMenu() {

  const [anchorEl, setAnchorEl] = React.useState(null);


  const user_type = localStorage.getItem(USER_TYPE);
  const token = localStorage.getItem(ACCESS_TOKEN_NAME);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const history = useHistory();

  const routeChangeSettingsPersonal = () =>{ 
    let path = `/SettingsPersonal`; 
    history.push(path);
  }

  const routeChangeSettingsPortfolio= () =>{ 
    let path = `/SettingsPortfolio`; 
    history.push(path);
  }

  const routeChangeSettingsCommission= () =>{ 
    let path = `/SettingsCommission`; 
    history.push(path);
  }

  const routeChangeSettingsAccount= () =>{ 
    let path = `/SettingsAccount`; 
    history.push(path);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        color='inherit'
        disabled = {!token}
      >
        <SettingsIcon/>
      </IconButton>


      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick = {routeChangeSettingsPersonal} style ={{fontVariant: 'small-caps', fontSize:'50'}}>
          <ListItemIcon >
            <PersonIcon  />
          </ListItemIcon>
          <ListItemText primary="PERSONAL" />
        </StyledMenuItem>

        <StyledMenuItem onClick = {routeChangeSettingsPortfolio} style ={{fontVariant: 'small-caps'}}>
          <ListItemIcon>
            <ImageIcon  />
          </ListItemIcon>
          <ListItemText primary= {user_type == 'artist' ? "PORTFOLIO" : "COLLECTION"} />
        </StyledMenuItem>
       
        <StyledMenuItem onClick = {routeChangeSettingsCommission} style={{fontVariant: 'small-caps', display: user_type == 'artist' ? "" : "none"}}>
          <ListItemIcon>
            <BrushIcon  />
          </ListItemIcon>
          <ListItemText primary="COMMISSIONS" />
        </StyledMenuItem>

         <StyledMenuItem onClick = {routeChangeSettingsAccount} style={{fontVariant: 'small-caps'}}>
          <ListItemIcon>
            <ManageAccountsIcon  />
          </ListItemIcon>
          <ListItemText primary="ACCOUNT" />
        </StyledMenuItem>


      </StyledMenu>



    </div>
  );
}