import React, {useState} from 'react';
import {  Container, Button, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import logoIcon from './logo_fontana.png';

import Background from './cretto_burri.jpg';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import AlertComponent from './AlertComponent';
import LoginGoogle from './LoginGoogle'

import {BASE_URL, ACCESS_TOKEN_NAME, ACCESS_TICKET_NAME, USER_ID, USER_TYPE, HOME, ACCESS_TOKEN_TIME} from '../constants/apiConstants'

import './LoginHome.css'


const ButtonStyles = makeStyles({
  root: {
    background: 'white',
    border: 10,
    borderColor: 'black',
    borderRadius: 3,
    marginTop: '5px',
    color: 'black',
    height: 48,
    padding: '0 30px',
    fontSize: '15px',
  },

  register: {
    background: 'white',
    border: 10,
    borderColor: 'black',
    borderRadius: 83,
    marginTop: '5px',
    color: 'black',
    height: 48,
    padding: '0 30px',
    fontSize: '15px',
  },
});





function LoginHome(props) {


   const [pswd, pswdSet] = useState('')

   const [screen, screenSet] = useState(1)
   const [email, emailSet] = useState(null)
   const [user_type, user_typeSet] = useState(null);

   const[userVerification, userVerificationSet] = useState('')

   const [state , setState] = useState({
        email : "",
        password : "",
        successMessage: null
    })

     const [errorMessage, updateErrorMessage] = useState(null);
     const [severity, severitySet] = useState("success")
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const handleSubmitClick = (e) => {

        
        const payload={
            "email":state.email,
            "password":state.password,
        }

     

     
        



        axios({
            method: "post",
            
            url: BASE_URL+"oauth/login",
            data: payload,
            headers: {
            'Content-Type': 'text/plain;charset=utf-8',
            }
        })
            .then(function (response, error) {

               


                if(response.status === 200){

                    updateErrorMessage('You are in')
                    setState(prevState => ({
                        ...prevState,
                        'successMessage' : 'Login successful. Redirecting to home page..'
                    }))

                    
                    localStorage.setItem(ACCESS_TOKEN_NAME,response.data.access_token);
                    localStorage.setItem(ACCESS_TICKET_NAME,response.data.ticket);
                    localStorage.setItem(USER_ID,response.data.user_id);
                    localStorage.setItem(USER_TYPE,response.data.user_type);
                    localStorage.setItem(ACCESS_TOKEN_TIME, new Date())
                                      
                  
                    redirectToHome();
                    updateErrorMessage(null)
                }
                
            })
            .catch(function (error) {

              
              //console.log(response.response.status)
             
              if(error.response.status === 406){
                  screenSet(4)
                }


              updateErrorMessage("Incorrect username or password")

                
                
                //if(error.response.status === 401){
                //    updateErrorMessage("Incorrect username or password");
                //}
                //else{
                //    updateErrorMessage("Incorrect username or password");
                //}

                
            });
    }

    

    const redirectToHome = () => {
       
    
    window.location = HOME
    //history.push('./')

    }

    const handleUser_type = (e, value) => {

    
    user_typeSet(prevState => value)

    }

    const handleGoogleReg = () => {

    axios({
                                  method: "post",
                                  url: BASE_URL + "oauth/google/register",
                                  data: {user_type: user_type,
                                         email: email},
                                  headers: {
                                  'Content-Type': 'text/plain;charset=utf-8'

                                  }
                              })  

                              .then(function (response) {
                                  
                                  if(response.status === 201){

                                      localStorage.setItem(ACCESS_TOKEN_NAME,response.data.access_token);
                                      localStorage.setItem(ACCESS_TICKET_NAME,response.data.ticket);
                                      localStorage.setItem(USER_ID,response.data.user_id);
                                      localStorage.setItem(USER_TYPE,response.data.user_type);
                                      redirectToHome();
                        
                                    }
                                  })

                              .catch(function (error) {
                    
                });  



      

  }

  const handleSubmitVerification = () =>{

    axios({
                                  method: "post",
                                  url: BASE_URL + "oauth/resend" ,
                                  data: {
                                         email: state.email},
                                  headers: {
                                  'Content-Type': 'text/plain;charset=utf-8'

                                  }
                              })  

                              .then(function (response) {

                                 severitySet('success')
                                 updateErrorMessage('an email has been sent')
                                 

                                  

                                  })

                              .catch(function (error) {

                                 severitySet('warning')
                                 updateErrorMessage('an error occured, please try again')
                    
                });   
}

  const history = useHistory();

  const routeChangeRegister = () =>{ 
    let path = `./Register`; 
    history.push(path);
  }

  const handleSubmitPassword = () =>{ 
    if(pswd == 'Malevich'){

      screenSet(1)
    }
  }

  const handlePassword = (e) =>{ 
    pswdSet(e.target.value)
  }

   const classes = ButtonStyles();


if(screen ===1){

  return (


    <div className = 'background'>

    <form>
        
      <div className = 'preContainer'>

        <Container maxWidth="md">

          
            
             <div className = 'containerContent'>

                <img className = 'logo'  src={logoIcon}  alt="Logo_adam" onClick = {redirectToHome} style={{cursor:'pointer'}}/>

                  
                <div className='inputsContainer' >
                    <Input style = {{backgroundColor: "white"}} type="email" placeholder= "E-mail" id="email" aria-describedby="emailHelp" value={state.email} onChange={handleChange}/>
                    <Input style = {{backgroundColor: "white"}} 
                           type="password" 
                           placeholder= "Password" 
                           id="password"  
                           value={state.password} 
                           onChange={handleChange}

                           onKeyDown = {(e) => {
                                      if (e.key === 'Enter') {
                                          handleSubmitClick()    
                                                }
                                            }}/>
                    

                    <Button 
                     variant="contained" 
                     className={classes.root} 
                     onClick={handleSubmitClick}
                     >

                     Log in

                     </Button>


                    <Button style ={{display: ''}} variant="contained" className={classes.register} onClick={routeChangeRegister} > sign in  </Button> 
                    
                   
                

                </div>


                  <div className = 'loginGoogleButton'>  
                  <LoginGoogle screenSet = {screenSet} emailSet = {emailSet}/>
                  </div>

                  <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity="error"/>

              </div>

          

        </Container>



      </div>

            
      </form>



      

       </div >
  )}

  if(screen ===2){

  return (


    <div className = 'background'>

    <form>
        
      <div className = 'preContainer'>

        <Container maxWidth="md">

          
            
             <div className = 'containerContent'>   

                <img className = 'logo'  src={logoIcon}  alt="Logo_adam"/>

                <div className = 'inputsContainer'>

                  <h1 className = 'welcomeToMecenate'> Welcome to Mecenate  </h1>
                  
                  <p className = 'pleseChoose'> Please choose your account type  </p>

                   <div className = 'inputAccountType'> 

                    <Autocomplete

                    onChange={handleUser_type}
                    value={user_type}
                    style={{marginTop: '-15px', fontVariant: 'small-caps', color: 'black', fontSize: '30px'}}
                    options={['artist','commissioner']}
                    renderInput={(params) => <TextField {...params} label="account type" />}
                  />
                 </div> 

                
                  <Button  onClick = {handleGoogleReg} variant = 'contained' className={classes.register} style = {{ marginTop: '15px'}}>
                   Register
                  </Button>
                
              
                  
               </div>

              </div>

          

        </Container>



      </div>

            
      </form>



      

       </div >
  )}

    if(screen === 0){

  return (


    <div style={{display:'flex', justifyContent:'center', backgroundImage: "url(" + Background + ")", maxWidth:'100vw',height:'100vh', backgroundSize: 'cover' }}>

    <form>
        
      <div style = {{marginTop:'200px', display:'flex', justifyContent:'center'}}>

        <Container maxWidth="md">

          <div style = {{ backgroundColor: 'rgba(255, 255, 255, 0.7)'}}>
            
             <div style={{display:'flex', flexDirection:'column', padding: '30px', width:'350px'}}>   

                <img style = {{width: "180px", marginLeft:'auto',marginRight:'auto'}}  src={logoIcon}  alt="Logo_adam"/>

                <div style = {{ backgroundColor: 'rgba(255, 255, 255, 0.6)', padding: '10px'}}>

                  <h1 style = {{fontVariant: 'small-caps', color: 'black', fontSize: '30px' }}> Insert password  </h1>
                  
                  

                  <Input type = 'password' value = {pswd} onChange = {handlePassword}/>
                  <Button variant = 'outlined' onClick ={handleSubmitPassword} > SEND </Button>

                
                  
                
              
                  
               </div>

              </div>

          </div>

        </Container>



      </div>

            
      </form>


       </div >
  )}

      if(screen === 4){

  return (

      <div className = 'background'>

    <form>
        
      <div className = 'preContainer'>

        <Container maxWidth="md">

          
            
             <div className = 'containerContent'>

                <img className = 'logo'  src={logoIcon}  alt="Logo_adam" onClick = {redirectToHome} style={{cursor:'pointer'}}/>

                  
                <div className='inputsContainer' >

                  <div className ={'textContainer'}>
                    
                    Your account is not verified. Please verify your account before login. If you need another verification link please push the button below, we'll resend an email to you.

                  </div>  

                    <Button 
                     variant="contained" 
                     className={classes.root} 
                     onClick={handleSubmitVerification}
                     >

                     send new verification email

                     </Button>


                </div>

              </div>

              <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity= {severity}/>

          

        </Container>



      </div>

            
      </form>
      </div>


    
  )}
}

export default LoginHome;
