import React from 'react';
import './BottomBar.css';
import {Link} from "react-router-dom";




function BottomBar(props) {




  return (

        <div className = 'mainContainer'>
          <hr className = 'bottomLine'/>
          <div className = 'barContainer'>
            
            <div className = 'bottomBarAbout'> <Link to ='/about'> About </Link> </div>
            <div className = 'bottomBarAbout'> <Link to ='/contactus'> Contact Us </Link> </div>
            
            <div className = 'bottomBarMecenate'> artMecenate&copy; 2022  </div>

          </div>

       </div >
  );
}

export default BottomBar;