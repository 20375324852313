import React, {useState} from 'react';
import { Button } from '@material-ui/core';
import './User.css'
import {BASE_URL, ACCESS_TOKEN_NAME, USER_ID, USER_TYPE, HOME} from '../constants/apiConstants'
import picture from './Profile_pic.png' 
import axios from 'axios';
import Link from '@material-ui/core/Link';
import AlertComponent from './AlertComponent';
import Modal from '@material-ui/core/Modal';
import './User.css'



function NotificationConversation(props) {

    
  const [modalOpen, modalOpenSet] = useState(false)
  
  const user_type = localStorage.getItem(USER_TYPE);
  const [errorMessage, updateErrorMessage] = useState(null);



	 const decisionRequest = (e, value) => { 


        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const user_id = localStorage.getItem(USER_ID);


        axios({
              method: "post",
              
              url: BASE_URL + "commissions/accept",
              data: {
          					   commission_id: props.notification.commission_id,
                       commissioner_id: props.notification.commissioner,
          					   status: value,
          					   //timestamp: new Date()
					    },
              headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json'
          }
        })
            .then(res => {

              window.location.reload()
              
            })
            .catch(err => {

              console.log(err)
              updateErrorMessage("Something went wrong. Try again or contact us!")})




            axios({
              method: "post",
              
              url: BASE_URL + "chat/conversation",
              data: {                      
                       user_one: props.notification.commissioner,
                       status: value,
                       timestamp: new Date()
              },
              headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json'
          }
        })
            .then(res => {

              
              
            })
            .catch(err => console.log(err))


    };

       const decisionRequestCommissioner = (e, value) => { 


        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const user_id = localStorage.getItem(USER_ID);


            axios({
              method: "post",
              
              url: BASE_URL + "chat/conversation",
              data: {                      
                       user_one: props.notification.sender_id,
                       status: value,
                       timestamp: new Date(),
                       message: props.notification.message,
                       engagement_id: props.notification.engagement_id
              },
              headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json'
          }
        })
            .then(res => {

              window.location.reload()      
              
            })
            .catch(err => {

              console.log(err)
              updateErrorMessage("Something went wrong. Try again or contact us!")

            })


    };

    const linkURL = () => { 

    		let path = HOME + props.notification.user_one 

             window.location = path;

    }

  const handleModalClose = () => {
    modalOpenSet(false);
  };

  const handleModalOpen = () => {
    modalOpenSet(true);
  };


    const body = (
    <div className = 'commissionModal'>
     <div className = 'commissionModalInside'>

      <div className = 'modalMenuNameContainer'> 
         <img style={{height: '80px', borderRadius: '100%'}} src={props.notification.image_url}/>
         <div className = 'modalMenuName'> <Link href="#" onClick={linkURL} color = 'inherit'> {props.notification.first_name} {props.notification.last_name} </Link> </div>
      </div>   

       <div className = 'modalMenuEntriesContainer'>
        <div className = 'modalMenuEntryContainer'> <p className = 'modalMenuEntry'> COMMISSION TYPE: </p> <p>{props.notification.commission_type}</p> </div>
        <div className = 'modalMenuEntryContainer'> <p className = 'modalMenuEntry'>MEDIUM: </p> <p>{props.notification.commission_media}</p> </div>
        <div className = 'modalMenuEntryContainer'> <p className = 'modalMenuEntry'>MATTER: </p> <p>{props.notification.commission_matters}</p> </div>
        <div className = 'modalMenuEntryContainer'> <p className = 'modalMenuEntry'>DIMENSIONS: </p> <p>{props.notification.commission_width} X {props.notification.commission_height} inch (W x H)</p> </div>
        <div className = 'modalMenuEntryContainer'> <p className = 'modalMenuEntry'>PRICE: </p> <p>{props.notification.commission_price} $ </p> </div>
       </div>

       <p className = 'modalMenuEntry'> MESSAGE </p>
       <div className = 'modalMenuMessageContainer'>
         
         <p> {props.notification.commission_message}</p>
       </div>  

      <Button  variant = 'outlined'  onClick = {(e)=>{decisionRequest(e, 'ACCEPT')}}>  Accept </Button>
      <Button  variant = 'outlined'  onClick = {(e)=>{decisionRequest(e, 'REJECT')}}>  Decline </Button>

      <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity= 'warning'/>
     
     </div>
    </div>
  );

   const bodyCommissioner = (
    <div className = 'commissionModal'>
     <div className = 'commissionModalInside'>

      <div className = 'modalMenuNameContainer'> 
         <img style={{height: '80px', borderRadius: '100%'}} src={props.notification.image_url}/>
         <div className = 'modalMenuName'> <Link href="#" onClick={linkURL} color = 'inherit'> {props.notification.first_name} {props.notification.last_name} </Link> </div>
      </div>   

       

       <p className = 'modalMenuEntry'> MESSAGE </p>
       <div className = 'modalMenuMessageContainer'>
         
         <p> {props.notification.message}</p>
       </div>  

      

        <Button  variant = 'outlined'  onClick = {(e)=>{decisionRequestCommissioner(e, 'ACCEPT')}}>  Accept </Button>
        <Button  variant = 'outlined'  onClick = {(e)=>{decisionRequestCommissioner(e, 'REJECT')}}>  Decline </Button>

        <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity= 'warning'/>

        
     
     </div>
    </div>
  ); 


if(user_type == 'artist'){

  return (


  	<div>

  	    <div style={{ paddingBottom: '7px', fontSize:'12px' }} >

  	    	COMMISSION REQUEST

  	    </div>

        <div style={{display: 'flex', }}>

          <img style={{height: '30px', borderRadius: '100%'}} src={props.notification.image_url}/>

          <div style={{ marginLeft: '15px', fontSize:'20px' }}>

          	<Link href="#" onClick={linkURL} color = 'inherit'> {props.notification.first_name} {props.notification.last_name} </Link>
          </div>

        </div>

        <div style={{display: 'flex', marginTop:'10px'}}>  

          

          <Button style={{maxHeight: '40px', width: '100%'}} variant = 'outlined'  onClick = {handleModalOpen}>  See request </Button>
       </div >

       <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}

      </Modal>

    </div>
  )}

  if(user_type == 'commissioner'){
  
  return (


    <div>

        <div style={{ paddingBottom: '7px', fontSize:'12px' }} >

          MESSAGE REQUEST FROM

        </div>

        <div style={{display: 'flex', }}>

          <img style={{height: '30px', borderRadius: '100%'}} src={props.notification.image_url}/>

          <div style={{ marginLeft: '15px', fontSize:'20px' }}>

            <Link href="#" onClick={linkURL} color = 'inherit'> {props.notification.first_name} {props.notification.last_name} </Link>
          </div>

        </div>

        <div style={{display: 'flex', marginTop:'10px'}}>  

          

          <Button style={{maxHeight: '40px', width: '100%'}} variant = 'outlined'  onClick = {handleModalOpen}>  See request </Button>
       </div >

       <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyCommissioner}

      </Modal>

    </div>
  )}


}

export default NotificationConversation;