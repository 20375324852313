import React, {useState} from 'react';
import {BASE_URL, ACCESS_TOKEN_NAME, USER_ID, ACCESS_TICKET_NAME, HOME} from '../constants/apiConstants';
import { useParams} from "react-router-dom";
import {TextField, Input, Button } from '@material-ui/core';
import axios from 'axios';

import logoIcon from './Logo_logo.png';
import Background from './cretto_burri.jpg';


function Authentication(props) {

  let { authToken } = useParams();

  const [verified, verifiedSet] = useState(false)

  const [error, errorSet] = useState(false)


const handleClick = () =>{

          axios.get(BASE_URL + 'oauth/email/verify/'+authToken, {
          headers: {
            
          }
        })

        .then((res) => {

          verifiedSet(true)
         
        })

        .catch((error) => {
          
          errorSet(true)
          console.error(error)
        })    
}

  return (


        <div style ={{backgroundImage: `url(${Background})`, height: '100vh', backgroundPosition: 'center'}}>

           <div style={{backgroundColor: 'white',width:'220px'}}>
             <img style={{ width:'200px'}} src={logoIcon}/>
           </div>

          <div style={{display: 'flex', marginTop:'200px', marginLeft:'auto', marginRight:'auto', width: '70vw', alignContent: 'space-between', fontSize:'30px', backgroundColor:'white', padding:'10px',
                        borderStyle: 'solid', borderWidth: '2px', borderColor: 'black'}}>

              Click on the 'ACTIVATE ACCOUNT' button to activate your account

              <div style ={{marginLeft:'20px'}}> <Button variant = 'contained' size = 'large' color= 'primary' onClick = {handleClick}> ACTIVATE ACCOUNT </Button> </div>

          </div>

          <div style={{ display: verified ? 'block': 'none', marginTop:'20px', marginLeft:'auto', marginRight:'auto', width: '70vw', alignContent: 'space-between', fontSize:'30px', backgroundColor:'white', padding:'10px',
                        borderStyle: 'solid', borderWidth: '2px', borderColor: 'black', color:'green', fontWeight:'500'}}>

              Your account has been verified! click <a href="https://artmecenate.com/loginhome"> here</a> to Login into your account!

          </div>

          <div style={{ display: error ? 'block': 'none', marginTop:'20px', marginLeft:'auto', marginRight:'auto', width: '70vw', alignContent: 'space-between', fontSize:'30px', backgroundColor:'white', padding:'10px',
                        borderStyle: 'solid', borderWidth: '2px', borderColor: 'black', color:'red', fontWeight:'500'}}>

              There was an error. Please try again or contact the artmecenate.com support

          </div>
            
        </div>

        

  )
}

export default Authentication;
