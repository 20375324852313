import React, {useState, useEffect} from 'react';
import {TextField, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';


function SettingPortfolioEntry(props) {

	const imageN  = 'image'  + props.index
	const image_N = 'image_' + props.index

	//const [title, titleSet] = useState('') 
	//const [matter, matterSet] = useState('') 
	//const [dimension_one, dimension_oneSet] = useState('')
	//const [dimension_two, dimension_twoSet] = useState('')
	//const [tags, tagsSet] = useState('')
   
	const [entries, entriesSet] = useState({title:'', author: '', matter: '', dimension_one: '', dimension_two:'', tags:''})
 
    const [load, loadSet] = useState(true)

	const handleTags = (e) => {

        const {id , value} = e.target 
        

        entriesSet(prevState => ({
            ...prevState,
            [id] : value
        }))
        	
	}

	const onImageLoad = (e) => {

        loadSet(false)
        	
	}

	useEffect(() => {
      
         if(props.URLdescription.split(';')[0]){

         	var dim1 = parseInt(props.URLdescription.split(';')[3].split('x')[0])
         	var dim2 = parseInt(props.URLdescription.split(';')[3].split('x')[1])

			entriesSet(prevState => ({
	            ...prevState,
	            'title' : props.URLdescription.split(';')[0],
	            'author' : props.URLdescription.split(';')[1],
	            'matter' : props.URLdescription.split(';')[2],
                'dimension_one': dim1,
                'dimension_two': dim2,
	            'tags' : props.URLdescription.split(';')[4],
	        }))
	     }

	},[props.URLdescription])


	useEffect(() => {

		loadSet(true)

	},[props.URLimage])



	useEffect(() => {

        const identifier = props.descriptId
		props.setImgObj(prevState => ({
            ...prevState,
            [identifier] : entries.title + ';'+ entries.author + ';' + entries.matter + ';'+ entries.dimension_one + ' x ' +entries.dimension_two + ';' + entries.tags
        }))

        console.log('entries is:' + entries.title)



	}, [entries])




	return(

		<div className = "SettingsPortfolioPicModule">




                  <div className = "SettingsPortfolioPicDiv">
		          	<img className = "SettingsPortfolioPics" src={props.URLimage} onLoad = {onImageLoad}/> 
		          	<CircularProgress style = {{width: '20px', height:'20px', display: load ? 'block':'none'}} />
		          </div>

		          <div className = "SettingsPortfolioInput">
		          	 <div>
			          <input type="file" accept="image/*" onChange={props.handleImageChange} id={imageN} style={{display: "none"}}/> 


			         	<label htmlFor={imageN}>


			         		 <Button variant="outlined" component="span" >
			            			choose picture #{props.index}
			          		 </Button>


			         	</label>

			         	 <input type="button" id={image_N} onClick={props.handleDelete} style={{display:'none'}}/>

			         	 <label htmlFor={image_N}>

			         		 <Button variant="outlined" component="span" >
			            			delete picture #{props.index}
			          	 	 </Button>

			          	 </label>
			          	


			        	</div>

			        	<div style={{ marginTop: "10px", fontVariant:'small-caps'}}>

                          <div style = {{ display: 'flex' }}>

                              <div className = 'artworkTitle'>
				        	    <TextField inputProps={{ maxLength: 50 }} variant = "outlined" fullWidth  placeholder="title (50 chars max)" id= 'title' value={entries.title} onChange={handleTags} size = 'small'/>
				        	  </div>
				        	  <div className = 'artworkAuthor'>
				        	    <TextField inputProps={{ maxLength: 50 }} variant = "outlined" fullWidth  placeholder="author" id= 'author' value={entries.author} onChange={handleTags} size = 'small'/>
                              </div>
                          </div>

			        	  <div className = 'artworkDescription'>

                            <div className = 'artworkDescriptionDescription'> 
			              		<TextField inputProps={{ maxLength: 50 }} variant = "outlined" placeholder=" e.g. oil on canvas" id="description1" id = 'matter' value={entries.matter} onChange={handleTags} size = 'small'/>
			                </div>
			                <p> size: </p>
			                <div className = 'artworkSizeContainer'>
			                	<TextField variant = "outlined" type = "number" placeholder="50" size = 'small' className = 'artworkSize' id = 'dimension_one' value={entries.dimension_one} onChange={handleTags}/>
			                </div>
			                <p> X </p>
			                <div className = 'artworkSizeContainer'>
			                	<TextField variant = "outlined" type = "number" placeholder="50" size = 'small' className = 'artworkSize' id = 'dimension_two' value={entries.dimension_two} onChange={handleTags}/>
                            </div>
                            <p> inches </p>
			              </div>

			              <TextField inputProps={{ maxLength: 50 }} variant = "outlined"  fullWidth placeholder="tags (50 chars max)" size = 'small' id = 'tags' value = {entries.tags} onChange = {handleTags}/>

		                </div>
		            </div>
	         	</div>

		)
}



export default SettingPortfolioEntry;