
import React, { useState, useEffect } from 'react';
import { Button, IconButton} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Alert} from '@material-ui/lab';
import CancelIcon from '@material-ui/icons/Cancel';



const ButtonStyles = makeStyles({


  close: {
    
    color: 'inherit',
    maxHeight:'16px',
    maxWidth:'16px',

    
     '&:hover': {
      backgroundColor: "rgba(255,10,0,0)",
      color: "rgba(200,0,0,1)"
    }

  },
});


function AlertComponent(props) {

    const [modalDisplay, toggleDisplay] = useState('none');

    const openModal = () => {
        toggleDisplay('flex');     
    }
    const closeModal = () => {
        toggleDisplay('none'); 
        props.hideError(null);
    }
    useEffect(() => {
        if(props.errorMessage !== null) {
            openModal()
        } else {
            closeModal()
        }
    });


    const classes = ButtonStyles();
    
    return(

        <div       
            role="alert" 
            id="alertPopUp"
            style={{ display: modalDisplay, flexDirection: 'column' }}
        >

           

           <div style ={{marginLeft:'auto', marginRight: '-10px', marginBottom: '-12px'}}>

               <IconButton 

                        
                    className = {classes.close}
                    
                    type="button" aria-label="Close" 
                    onClick={() => closeModal()}>

                    <CancelIcon fontSize = 'small' style={{height:'15px'}}/>

               </IconButton>

            </div>

                <Alert  severity={props.severity} style={{display: 'flex', justifyContent:'center'}}>
                   
                <span>{props.errorMessage}</span>
                
           </Alert> 
           

             
               
            
            
        </div>
    )
} 

export default AlertComponent