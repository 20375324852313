import React, {useState, useRef, useEffect} from "react";
import {BASE_URL, ACCESS_TOKEN_NAME, USER_ID, ACCESS_TICKET_NAME, HOME, USER_TYPE} from '../constants/apiConstants'
import axios from 'axios';
import { useParams} from "react-router-dom";
import { ChatList } from 'react-chat-elements'
import './ChatElement.css'
import ChatMessage from './ChatMessage.js'
import './Chat.css';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {TextField, Button} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ImageIcon from '@material-ui/icons/Image';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

import whiteboardIcon from './whiteboard_icon.png'; 

import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';

import Whiteboard from './ChatWhiteboard.js'
import ChatCommissionCenter from './ChatCommissionCenter.js'
import logoIcon from './Logo_logo.png';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  

  
  drawer: {

    marginTop: 40,
    width: "240px",
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    marginTop: 66,
    width: "240px",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {

    marginTop: 66,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 70,
    
  },
  
 
}));




function Chat(props) {

  let { conversationID } = useParams();
  const history = useHistory();

  const user_type = localStorage.getItem(USER_TYPE)
  

  const [open, setOpen] = useState(true);
  const [textMessage, textMessageSet] = useState("");
  const [pastMessages, pastMessagesSet] = useState([]);

  const [pagOffset, pagOffsetSet] = useState(0);
  const [pagLimit, pagLimitSet] = useState(10);

  const [uploadImage, uploadImageSet] = useState({});
  
  const [screenSelector, screenSelectorSet] = useState('chat')

  

  const inbox = props.in_box
  const sendMessage = props.send_Message

  const messageDisplay = pastMessages.concat(inbox)

  const messageDisplayFiltered = messageDisplay.filter((message)=>( message.conversation_id == conversationID))

  const date = new Date()

  const classes = useStyles();

  React.useEffect(() => {
    
       
        
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const userID = localStorage.getItem(USER_ID);
    

        axios.get(BASE_URL + 'chat/conversations/'+conversationID+'?offset=0&limit=10', {
          headers: {
            'Authorization': 'Bearer '+ token,
          }
        })

        .then((res) => {

          //console.log(res)
          pastMessagesSet(res.data)
            
        })

        .catch((error) => {
          console.error(error)
        }) 

  }, [conversationID]);

  
const handlePagination = () => {
      
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        
        pagOffsetSet(pagOffset + 10)
        const Off = pagOffset + 10

        pagLimitSet(pagLimit + 10)
        const Lim = pagLimit + 10
        

        axios.get(BASE_URL + 'chat/conversations/'+conversationID+'?offset='+Off+'&limit='+ Lim, {
          headers: {
            'Authorization': 'Bearer '+ token,
          }
        })

        .then((res) => {

          //console.log(res)
          pastMessagesSet(res.data.concat(pastMessages))
            
        })

        .catch((error) => {
          console.error(error)
        }) 
    

  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleTextChange = (e) => {
        
        const {id , value} = e.target 
         textMessageSet(value)

    }
 

     const handleSendMessage = () => {

        //console.log(conversationID)
        const date = new Date()

        if(textMessage){

          sendMessage({   message:          textMessage,
                          timestamp:        date,
                          message_type:     "TEXT",
                          conversation_id:   conversationID})

          textMessageSet("")
        }
    }

     const handleTextSelect = (e) => {

        const lastMessage =  messageDisplay[messageDisplay.length - 1]

        const user_id = localStorage.getItem(USER_ID);

        if (!textMessage && lastMessage.sender_id != user_id){  

        

        sendMessage({   message:          '',
                        timestamp:        '',
                        message_type:     "SERVICE",
                        conversation_id:   conversationID,
                        message_id: lastMessage.message_id})
        

      }

    }

   

    const messageEl = useRef(null)

    useEffect(() => {
    if (messageEl) {
      messageEl.current.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  }, [])


    const messageSide = useRef(null)

    useEffect(() => {
    if (messageSide) {
      messageSide.current.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  }, [])


  
  const conversationManager = (e) => {       
        
      //console.log(e.conversation_id);
      //window.location = HOME +`chat/`+ e.conversation_id ;

      history.push('/chat/'+ e.conversation_id)
    }

  
const HandleInsertImageChat = (e) =>{

  //console.log('ciao')
  //console.log(e.target.files[0].type)
  
  var extension = e.target.files[0].type
  const date = new Date()
  var reader = new FileReader();

  reader.onloadend = function() {

    //console.log(reader.result)

    sendMessage({       message:           reader.result,
                        timestamp:         date,
                        message_type:      "IMAGE",
                        conversation_id:   conversationID,
                        image_ext:         extension })

    //console.log('RESULT', reader.result)
    

  }
  reader.readAsDataURL(e.target.files[0]);
}

const handleWhiteboard = () => {

  screenSelectorSet('WB')
  

}

const handleCommissionCenter = () => {

  screenSelectorSet('CC')
  
}

const handleChat = () => {

  screenSelectorSet('chat')
  
}
 
 console.log(messageDisplayFiltered)

  return (

    <div style={{display:'flex'}}>
               
      <div className = "drawerContainer">
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerToggle}>
                {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </div>
            <Divider />

             <List>
                                 
                       

                       <ListItem button key= 'commission' onClick={handleCommissionCenter} style={{display: 'none',fontWeight: 'bold', fontVariant:'small-caps', fontSize: '15px'}}>
                         
                          <img style = {{width: '37px'}}  src={logoIcon}  alt="CommissionIcon" />
                          <p style={{marginLeft: '19px'}}> commission status </p>
                       </ListItem>

                       <ListItem button key= 'chat' onClick={handleChat} style={{fontWeight: 'bold', fontVariant:'small-caps', fontSize: '15px'}}>
                         
                          <ChatBubbleOutlineIcon fontSize = 'large' />
                          <p style={{marginLeft: '19px'}}> chat </p>
                       </ListItem>

                       
                                 
                       <ListItem button key= 'WB toggle' onClick={handleWhiteboard} style={{fontWeight: 'bold', fontVariant:'small-caps', fontSize: '15px'}}>
                         <img style = {{width: '37px'}}  src={whiteboardIcon}  alt="whiteboardIcon" />
                         <p style={{marginLeft: '19px'}}> whiteboard </p>
                       </ListItem>
                    
              </List>

            <Divider />
            
            <div style = {{ display: screenSelector == 'chat' ? "" : "none"}}>
              <ChatList

              
              className='chat-list'
              onClick= {conversationManager}
              dataSource= {props.active_conversations[1]}
            />
           </div>

           <div className = 'MessageListSide' ref={messageSide}>

            <List style ={{display: screenSelector != 'chat' & open ? "" : "none"}}>
            
            { 
              messageDisplay.map((messageobj, i) => (

                <div key = {i} style={{ display: messageobj.conversation_id == conversationID ? "flex" : "none", marginLeft: '5px', marginRight: '7px'}}>
                  <ChatMessage key = {messageobj.message_id} newmessage = {messageobj} type = 'sidebar'/>
                </div>
              
              ))}

            </List>
           </div> 
            
          </Drawer>


      </div>



      <div className = 'MessagePanel'>

       <div style = {{ display: screenSelector == 'chat' ? "" : "none",  }}>

          <div className = "paginationArrow">
            <IconButton  onClick = {handlePagination}> <ExpandLess /> </IconButton> 
          </div>

          <div className = "MessageList" ref={messageEl}>

          <List>
            
            { 
              messageDisplay.map((messageobj, i) => (

                <div key = {'a'+messageobj.message_id} style={{ display: messageobj.conversation_id == conversationID ? "block" : "none"}}>
                  <ChatMessage key = {messageobj.message_id} newmessage = {messageobj} type = 'main'/>
                </div>
              
              ))}
              
              {/*
              <div style = {{ display: user_type =='commissioner' && messageDisplay.length == 1 ? 'block' : 'none', margin: '10px'}}>
                <Button variant = 'outlined'> Accept </Button> <Button color = 'secondary' variant = 'outlined'> Block User </Button>
              </div>
              */}

            </List>
           </div>
         </div>

         <div style = {{ display: screenSelector == 'WB' ? "" : "none", marginTop: '-35px' }}>
          <Whiteboard in_box = {inbox} send_Message = {sendMessage} conversation_id = {conversationID}/>
         </div>

         <div style = {{ display: screenSelector == 'CC' ? "" : "none", marginTop: '-35px' }}>
          <ChatCommissionCenter/>
         </div>



        <div> 
         <form style = { {display: screenSelector != 'chat' & !open ? "none" : "" }}>

            <div className = "bottomBar"> 

                <div className = "InputText">
                    <TextField

                      className = 'Text'
                      id="inputText"
                      fullWidth
                      multiline
                      disabled = {messageDisplayFiltered[0] ? messageDisplayFiltered[0].sender_id == '1d8eaf55-e217-46fc-8cfe-b80713f347bf' : false}
                      rows={2}
                      placeholder="Insert text"
                      variant="outlined"
                      value = {textMessage}
                      onChange={handleTextChange}

                      onKeyDown = {(e) => {
                            if (e.key === 'Enter') {
                              
                              handleSendMessage()
                              
                            }

                       }}

                       onKeyUp = {(e) => {
                            if (e.key === 'Enter') {
                              
                              
                              textMessageSet("")
                            }

                       }}

                       onSelect={handleTextSelect}



                    />
                </div>

                <div className = "sendIcon"> 

                         <IconButton onClick = {handleSendMessage}> <SendIcon /> </IconButton> 

                         <input type="file" accept="image/*" onChange={HandleInsertImageChat} id= 'insertImageChat' style={{display: "none"}}/> 
                            <label htmlFor='insertImageChat'>
                               <IconButton variant="outlined" component="span" style={{display: 'none'}}>
                                     <ImageIcon/> 
                                 </IconButton>
                            </label> 
                          
                </div>
              </div>

         </form>
        </div>

      </div>


      
    </div>
  );
}



export default Chat;