import React, {useState} from 'react';
import {TextField, Input, Button } from '@material-ui/core';
import {BASE_URL, ACCESS_TOKEN_NAME, USER_ID, ACCESS_TICKET_NAME, HOME} from '../constants/apiConstants'
import Modal from '@material-ui/core/Modal';
import AlertComponent from './AlertComponent';
import axios from 'axios';
import './Settings.css'



function SettingsAccount(props) {


    const [modalOpen, modalOpenSet] = useState(false)
    const [errorMessage, updateErrorMessage] = useState(null);
    const [severity, severityUpdate] = useState("success");

    const handleModalClose = () => {
      modalOpenSet(false);
    };

    const handleModalOpen = () => {
      modalOpenSet(true);
    };



    const deleteAccount = () => { 
       

        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const userID = localStorage.getItem(USER_ID);

        axios({
              method: "DELETE",
              url: BASE_URL + "deletes/account/" + userID ,
              
              headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json'
          }
        })
            .then(res => {

              

                        updateErrorMessage("The request was sent! Click anywhere to close the window ");
                        severityUpdate("success")
                        let path = HOME; 
                        window.location = path;
                      


            })
            .catch(function (error) {

                    if(error.response.status === 200){
                        updateErrorMessage("The request was sent! Click anywhere to close the window ");
                        severityUpdate("success")
                        let path = HOME; 
                        window.location = path;
                      }
                    
                    else{
                    updateErrorMessage("An error occured. Please try again. If the problem persists please contact the support team");
                    severityUpdate("warning"); }
                    
                });
          }


            


         const body = (
            <div className = 'settingsAccountModal'>

              <div className = 'settingsAccountModalInside'>

              <h2 className = 'settingsAccountModalTitle'> ARE YOU SURE YOU WANT TO DELETE YOUR ACCOUNT? </h2>

                <div>         
                  <Button variant = 'outlined' size = 'large' onClick = {deleteAccount}> YES, DELETE ACCOUNT</Button>    
                </div>

                <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity={severity}/>
              

             </div>      
            </div>
          );


  return (


        <div >

          <div className = "SettingsPersonalPage">
        
            <h1 className = 'SettingsPersonalMainTitle'> Account </h1>

            <p className = "SettingsPersonalHeaders"> DELETE ACCOUNT </p>

            <hr className = "SettingsPersonalLine"/>


            <div style ={{ display: 'flex', fontVariant: 'small-caps', fontSize: '18px', alignItems: 'center'  }}>
                <Button variant='outlined' size='large' onClick ={handleModalOpen}> delete account</Button>
                <div style = {{marginLeft:'20px'}}> Press this button to delete your account. All your infromation stored in Mecenate, including images and chats will be deleted</div>
            </div>

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                >
                {body}
            </Modal>

          </div>
            
        </div>


  )
}

export default SettingsAccount;
