import React, {useState} from 'react';
import './About.css';
import BottomBar from './BottomBar';
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import AlertComponent from './AlertComponent';
import {BASE_URL} from '../constants/apiConstants'

function AboutRegistrationForm(props) {

  const [ fullname, fullnameSet] = useState('')
  const [ email, emailSet] = useState('') 
  const [ portfolio, portfolioSet] = useState('') 
  const [ usertype, usertypeSet] = useState(null) 
  const [ message, messageSet] = useState('') 

  const [errorMessage, updateErrorMessage] = useState(null);
  const [severity, severityUpdate] = useState("success");

  const handleName = (e) => {

        fullnameSet(e.target.value)
  } 

  const handleEmail = (e) => {

        emailSet(e.target.value)
  }

  const handlePortfolio = (e) => {

        portfolioSet(e.target.value)
  }

  const handleUsertype = (e, value) => {

        usertypeSet(value)
  }

  const handleMessage = (e) => {

        messageSet(e.target.value)
  }

  const handleInquiry = (e) => { 

        axios({
              method: "post",
              url: BASE_URL + "oauth/registration/request",
              data: {
                full_name: fullname,
                email: email,
                instagram: portfolio,
                user_type: usertype,
                message: message
            },
              headers: {
            'Content-Type': 'application/json'
          }
        })
            .then(res => {

              if(res.status === 201){

                        updateErrorMessage("The request was sent! Click anywhere to close the window ");
                        severityUpdate("success")}
                
             
              
            })
            .catch(function (error) {
                    console.log(error);
                    updateErrorMessage("An error occured. Please try again. If the problem persists please contact the support team");
                    severityUpdate("warning"); 
                    
                });
       
    }




  return (

        <div >

          <div className='aboutMainContainer'>

            <h1 className='aboutMainTitle'> Registration Form </h1>

            <div className = 'RegistrationFormEntriesContainer'>
                <p className = 'RegistrationFormText'>
                    To join our community of Mecenates and artists and to have access to our exclusive services, please fill the registration form below
                    or send an email to <a className = 'RegistrationFormEntriesLink' href = {'mailto:artmecenate@gmail.com'}> artmecenate@gmail.com </a>

                </p>

                <TextField className = 'RegistrationFormEntries' label = 'Full name' value = {fullname} onChange = {handleName} />
                <TextField className = 'RegistrationFormEntries' label = 'E-mail' value = {email} onChange = {handleEmail} />
                <TextField className = 'RegistrationFormEntries' label = 'Insta profile or portfolio' value = {portfolio} onChange = {handlePortfolio}/>

                <Autocomplete

                          value={usertype}
                          onChange = {handleUsertype}
                          className = 'RegistrationFormEntries'
                          options={['artist','collector']}
                          renderInput={(params) => <TextField {...params} label="User type" />}
                        />

                <div className = 'RegistrationFormMessage'>
                <TextField className = 'RegistrationFormMessage'
                           value={message}
                           onChange = {handleMessage}
                           variant = 'outlined' 
                           multiline 
                           rows = {4} 
                           inputProps={{ maxLength: 500 }} 
                           placeholder = 'Leave a brief message about you and your interest in artMecenate (max 500 characters)'/>
                </div>

                <div className = 'RegistrationFormButton'>
                  <Button onClick = {handleInquiry} variant = 'outlined' > SUBMIT </Button>
                </div>
                <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity={severity}/>
            </div>        



           
          </div>  

          <div className = 'bottomBarFixed'>
  	       <BottomBar />
  	      </div>

          

       </div >
  );
}

export default AboutRegistrationForm;