
 import React, {useEffect, useState} from 'react';
 import axios from 'axios';
 import {ACCESS_TOKEN_NAME, BASE_URL, ACCESS_TOKEN_TIME, USER_ID, ACCESS_TICKET_NAME} from '../constants/apiConstants'



 function RefreshTokenSetup() {

  // Timing to renew access token

  const token = localStorage.getItem(ACCESS_TOKEN_NAME);
  const ticket = localStorage.getItem(ACCESS_TICKET_NAME);
  const [tokenTime, tokenTimeSet] = useState(localStorage.getItem(ACCESS_TOKEN_TIME));
  

  let refreshTime = 2 * 1000;
  

  



  useEffect(() => {

    const interval = setInterval(() => {

         const accessTime = new Date(tokenTime);
         const accessMinutes = accessTime.getMinutes()

          var d = new Date();
          //d.getHours(); // => 9
          const min = d.getMinutes(); // =>  30
          const sec = d.getSeconds(); // => 51

          
         if(token && Math.abs(accessMinutes - min)==30){

          console.log('refresh' + min  );


                      axios.get(BASE_URL + 'oauth/refresh_token/'+ ticket, {
                      headers: {
                        'Authorization': 'Bearer '+ token,
                      }
                    })

                    .then((res) => {

                         localStorage.setItem(ACCESS_TOKEN_NAME,res.data.access_token);
                         localStorage.setItem(ACCESS_TICKET_NAME,res.data.ticket);
                         localStorage.setItem(ACCESS_TOKEN_TIME, new Date());
                         
                         var time = new Date()
                         tokenTimeSet(time)
                         
                     
                    })

                    .catch((error) => {
                      console.error(error)
                    })    
            }        


        }, refreshTime);
        
        return () => clearInterval(interval); 

    },[tokenTime])


 return(<div> </div>)
};



export default RefreshTokenSetup