import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';



import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FlightIcon from '@material-ui/icons/Flight';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import CropDinIcon from '@material-ui/icons/CropDin';
import SchoolIcon from '@material-ui/icons/School';
import PublicIcon from '@material-ui/icons/Public';
import StoreIcon from '@material-ui/icons/Store';

import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from "react-router-dom";

import './User.css'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    marginLeft: '20px',
    marginTop: '50px',

    
  
  },
}));



export default function SimpleList(props) {

  const[mediums, mediumsSet] = useState([])

  const[matters, mattersSet] = useState([])


  const classes = useStyles();

  

  useEffect(() => {
    
    
    if(props.artistInformation.medium){
       
       var content = []
       content = props.artistInformation.medium.split(';')
       content.pop()
       mediumsSet(content)
    }

    if(props.artistInformation.matter){
       
       var content = []
       content = props.artistInformation.matter.split(';')
       content.pop()
       mattersSet(content)
    }


  
    },[props])


   const history = useHistory();
   const handleCommissionSettings = () => {
    
          let path = `/SettingsCommission`; 
          history.push(path);
  };


  return (
    
    <div className = 'userInfo'>


                
          <div className = {props.owner && props.artist ? 'SideLeftSettingsButton' : 'gallerySettingsButtonNone'} onClick= {handleCommissionSettings}>   <SettingsIcon/>  </div> 

          <List >

            <ListItem style ={{display: props.artistInformation.min_price ? '':'none'}}>
              <ListItemIcon>
                <MonetizationOnIcon />
              </ListItemIcon>
              <ListItemText primary= {props.artistInformation.min_price + " - " + props.artistInformation.max_price + " $"}/>
            </ListItem>
          
            <ListItem style ={{display: props.artistInformation.travel ? '':'none'}}>
              <ListItemIcon>
                <FlightIcon />
              </ListItemIcon>
              <ListItemText primary={props.artistInformation.travel} />
            </ListItem>
          
            <ListItem style ={{display: matters[0] ? '':'none'}}>
              <ListItemIcon>
                <ColorLensIcon />
              </ListItemIcon>
              <ListItemText primary= { matters.map(function Fun(messageobj, i) { var hyphen = ''; if(i<matters.length-1){ hyphen = ', '}; return (messageobj + hyphen)}) }  />
            </ListItem>

            <ListItem style ={{display: mediums[0] ? '':'none'}}>
              <ListItemIcon>
                <CropDinIcon />
              </ListItemIcon>
              <ListItemText primary= { mediums.map(function Fun(messageobj, i) { var hyphen = ''; if(i<mediums.length-1){ hyphen = ', '}; return (messageobj + hyphen)}) } />
            </ListItem>

             <ListItem style ={{display: props.profileInformation.institution ? '':'none'}}>
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary={props.profileInformation.institution} />
            </ListItem>

            <ListItem style ={{display: props.profileInformation.gallery ? '':'none'}}>
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary={props.profileInformation.gallery} />
            </ListItem>

            <ListItem style ={{display: props.profileInformation.city||props.profileInformation.state||props.profileInformation.country ? '':'none'}}>
              <ListItemIcon>
                <PublicIcon/>
              </ListItemIcon>

              <div className='infoEntry'>
              
              
                <ListItemText style ={{display: props.profileInformation.city ? '':'none'}}> {props.profileInformation.city + ', '} &nbsp; </ListItemText> 
                <ListItemText style ={{display: props.profileInformation.state ? '':'none'}}> { props.profileInformation.state + ', '}&nbsp;</ListItemText>
                <ListItemText style ={{display: props.profileInformation.country ? '':'none'}}> {props.profileInformation.country }</ListItemText>

              </div>
            </ListItem>

          </List>
        
      </div>  
  );
}
