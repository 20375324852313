import React from 'react';
import { ChatList } from 'react-chat-elements'

import 'react-chat-elements/dist/main.css'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import { useHistory } from "react-router-dom";
import {HOME, ACCESS_TOKEN_NAME} from '../constants/apiConstants';





const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));




export default function ChatMenu(props) {


  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = localStorage.getItem(ACCESS_TOKEN_NAME);
  
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  

  const listFunction = (e) => {       
        
        console.log(e.title);

    }


  const handleClose = () => {
    setAnchorEl(null);
  };

  const conversationManager = (e) => {       
        
        //console.log(e.conversation_id);
        
        history.push('/chat/'+e.conversation_id )

        //let path = HOME + `chat/`
        //window.location = path;

    }

    const activeConversationNumber = props.active_conversations ? props.active_conversations.length : 0



  return (
    <div>

     <Badge 
          badgeContent={props.unread} 
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
        }} 
        color="primary">
      <Button
        onClick={handleClick}
        disabled = {!token || activeConversationNumber <= 0}
      >
        messages
      </Button>
      </Badge>


      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        

          <ChatList

            className='chat-list'
            onClick= {conversationManager}
            dataSource= {props.active_conversations}
          />
       
      </StyledMenu>

    </div>
  );
}