import React from 'react';
import './About.css';
import BottomBar from './BottomBar';



function Phiosophy(props) {




  return (

        <div >

        <div className='aboutMainContainer'>

          <h1 className='aboutMainTitle'> Philosophy </h1>

          <div className='aboutContent'>

              <p>  
                Mecenate is the Agora for commissioners and artists. Mecenate is the only online platform 100% dedicated to art commissions and the only online space hosting both artists and collectors 
                profiles. If you are a consumed collector or you're just looking for your first one', if you are an established artist or if you are just emerging, if you love art, Mecenate is the 
                place for you.
              </p>


            <h1 className='aboutSectionTitle'> Why the name Mecenate? </h1>

              <p>
                Mecenate is probably the most famous patron of all times! He served as a quasi-culture minister to the first Roman emperor Octavian Augustus and helped a new generation 
                of poets - including both Horace and Virgil - to develop their art and mastepieces. His fame lasted the centuries so much that his name is synonimous of patron, and it is 
                with the name 'Mecenate' that the great patrons of the Renaissance, of Raffaello, Leonardo, and Michelangelo etc., used to be called. 
              </p>

            <h1 className='aboutSectionTitle'> Why commissions? </h1>

              <p>
                Commissions naturally bring together the artist, the commissioner, and ultimately, the viewers that can be your family, the clients of your business, or the lucky visitors of your 
                collection. Commissions are an opportunity for commissioners to acquire pieces of art that are incredibly relevant for themselves; but commissioners have also the opportunity to know 
                the artist and even more to be part of the creative process that generates an art piece.
              </p>
              <p>
                Artists have the freedom to accept or decline any commission proposal, and to know the commissioners from their profiles and social pages. Comissions are an opportunity to create
                something relevant, not only to one person, but relevant to the human kind. Commissions originates from life; like the great masters of the past such as Michelangelo, Leonardo,
                Chagall, and Rothko, you will have the chance to create an artwork that originates from the very human necessity and taht will speak a uinversal language. Commissions will help
                you reaching a broader audience and the collections of important patrons. More, commissions are a great way to secure some stable income that will help your art and your life as an
                artist.  
              </p>

            <h1 className='aboutSectionTitle'> Commissioners </h1>

              <p>
              A commissioner, or as we like to say, a Mecenate, is whoever is interested in commissioning an artpiece to an artist. As a commissioner you can have your own profile where to 
              showcase your collection, present yourself with a bio, and tell everbody a bit about yourself and your interest in art. You can be a collector looking for the next piece or
              you might just be looking to fill that big white wall of your new home with something beautiful and meaningful. On artMecenate you will have the opportunity to know many
              artists, watch their works and most importantly connect with them. We make asking for a commission easy and fun: you know the price of your commission before even asking
              and in case you decide to go for one you'll be guided through the process; let us take care of the technicalities and just enjoy the process!
              artMecenate is is your opportunity to show your love for art, your collection, knowing other collectors, new artists... and every pic of your collection can be shared by 
              everyone on the socials, so you can get the visibility and teh recognition that every patron and art lover deserves! 
              </p>

            <h1 className='aboutSectionTitle'> Artists </h1> 

            <p>
            Open your profile on artMecenate.com and join the first online agora designed for artists and commissioners. Don't dilute yourself and your art in the ocean of a generalistic container; stop
            getting molested through direct messages from people that are not really interested in you. On artMecenate.com commissioners can reach out to you only by proposing a commission...
            but you as an artist can direct message any commisioner!  
            Show your portfolio in an elegant and professional fashion, display all relevant information 
            about you in a compact and impactful way, let people share your art pieces and your profile on social networks in the easiest way, put in one place all your social pages and websites.
            </p>


          </div>
        </div>  

        <div className = 'bottomBarFixed'>
	       <BottomBar />
	      </div>

       </div >
  );
}

export default Phiosophy;





