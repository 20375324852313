import React from 'react';
import Link from '@material-ui/core/Link';
import { HOME} from '../constants/apiConstants'
import './Notification.css'


function NotificationFollow(props) {

    
    

 const linkURL = () => { 

        let path = HOME+props.notification.counterparty_id

             window.location = path;

    }


	



  return (


  	<div className = 'notificationFollow'>

    <div style={{ display: 'flex', backgroundColor: props.notification.notification_status == 'UNSEEN' ? "rgba(86,162,229,0.2)" : "" }}>
      <div style={{fontWeight:'bold'}}>
       <Link href="#" onClick={linkURL} color = 'inherit'> {props.notification.first_name} {props.notification.last_name} </Link>
      </div>
      <div style={{marginLeft: '4px'}}>
       {props.notification.notification}
      </div>
    </div>

  	  

    </div>
  );
}

export default NotificationFollow;