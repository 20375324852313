import React, {useState, useEffect} from 'react';
import { Button, Input, TextField } from '@material-ui/core';
import './User.css'
import axios from 'axios';
import {BASE_URL, ACCESS_TOKEN_NAME, USER_ID} from '../constants/apiConstants'
import { useParams} from "react-router-dom";
import freeForm from './Free_form.jpeg';
import portrait from './Portrait.jpeg';
import model from './Model.jpeg';
import fullCustom from './Cappella-sistina-volta.jpeg';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import SettingsIcon from '@material-ui/icons/Settings';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import AlertComponent from './AlertComponent';




function UserCommissions(props) {

  const [modalOpen, modalOpenSet] = useState(false)
  const [media, mediaSet] = useState(null)
  const [matter, matterSet] = useState("")
  const [height, heightSet] = useState(0)
  const [width, widthSet] = useState(0)
  const [message, messageSet] = useState("")
  const [price, priceSet] = useState(0)
  const [priceSelected, priceSelectedSet] = useState(0)

  const [email, emailSet] = useState('')
  const [inquirername, inquirernameSet] = useState('')

  const [commissiontype, commissiontypeSet] = useState('')
  const [mediaList, mediaListSet] = useState([])
  const [matterList, matterListSet] = useState([])
  const [priceList, priceListSet] = useState([])

  const [errorMessage, updateErrorMessage] = useState(null);
  const [severity, severityUpdate] = useState("success");

  const token = localStorage.getItem(ACCESS_TOKEN_NAME);

  

  let { user } = useParams();

   const requestConversation = () => { 

      if(!token){

        updateErrorMessage("Please login first or create an account");
                    severityUpdate("error"); 

      }
       
      if(token){

        const token = localStorage.getItem(ACCESS_TOKEN_NAME);

        axios({
              method: "post",
              url: BASE_URL + "commissions/request",
              data: { artist: user,
                      commission_type: commissiontype,
                      commission_message: message,
                      commission_matter: matter,
                      commission_media: media,
                      commission_width: width,
                      commission_height: height,
                      commission_price: price },
              headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json'
          }
        })
            .then(res => {

              if(res.status === 201){

                        updateErrorMessage("The request was sent! Click anywhere to close the window ");
                        severityUpdate("success")}
                
             
              
            })
            .catch(function (error) {
                    console.log(error);
                    updateErrorMessage("An error occured. Please try again. If the problem persists please contact the support team");
                    severityUpdate("warning"); 
                    
                });




          axios({
              method: "post",
              url: BASE_URL + "chat/invite",
              
              data: { user_two: user,
                     },

              headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json'
          }
        })
            .then(res => {

  
            })
            .catch(function (error) {
                   
                    
                });

          

       }
    }

       const handleInquiry = () => { 

        axios({
              method: "post",
              url: BASE_URL + "commissions/inquiry",
              data: { artist: user,
                      commission_type: commissiontype,
                      commission_message: message,
                      commission_matter: matter,
                      commission_media: media,
                      commission_width: width,
                      commission_height: height,
                      commission_price: price,
                      sender: inquirername,
                      sender_email: email },
              headers: {
            'Content-Type': 'application/json'
          }
        })
            .then(res => {

              if(res.status === 201){

                        updateErrorMessage("The request was sent! Click anywhere to close the window ");
                        severityUpdate("success")}
                
             
              
            })
            .catch(function (error) {
                    console.log(error);
                    updateErrorMessage("An error occured. Please try again. If the problem persists please contact the support team");
                    severityUpdate("warning"); 
                    
                });


          

       
    }

      useEffect(() => {
    
    
        if(props.artistInformation.medium){
           
           var content = []
           content = props.artistInformation.medium.split(';')
           content.pop()
           mediaListSet(content)
        }

        if(props.artistInformation.matter){
           
           var content = []
           content = props.artistInformation.matter.split(';')
           content.pop()
           matterListSet(content)
        }

        if(props.artistInformation.price){
           
           var content = []
           content = props.artistInformation.price.split(';')
           content.pop()
           priceListSet(content)

           
        }

        


  
    },[props])



   const handleFreeForm = () => { 

     commissiontypeSet('Free form')
     modalOpenSet(true);
     priceSet(0)
     priceSelectedSet(0)
     heightSet(0)
     widthSet(0)
     mediaSet('')
     matterSet('')
   } 
   const handlePortrait = () => { 

     commissiontypeSet('Portrait')
     modalOpenSet(true);
     priceSet(0)
     priceSelectedSet(0)
     heightSet(0)
     widthSet(0)
     mediaSet('')
     matterSet('')
   } 
   const handleModel = () => { 

     commissiontypeSet('Model')
     modalOpenSet(true);
     priceSet(0)
     priceSelectedSet(0)
     heightSet(0)
     widthSet(0)
     mediaSet('')
     matterSet('')
   } 
   const handleFullCustom = () => { 

     commissiontypeSet('Full Custom')
     modalOpenSet(true);
     priceSet(0)
     priceSelectedSet(0)
     heightSet(0)
     widthSet(0)
     mediaSet('')
     matterSet('')
   } 

    const handleModalClose = () => {
    modalOpenSet(false);
  };

   const handleMediaChange = (e) => {
    mediaSet(e.target.value);

    var index = 0;
    index = mediaList.indexOf(e.target.value);
    priceSelectedSet(priceList[index])
    var priceTemp = parseInt(priceList[index])
    priceSet(priceTemp*(parseInt(width)+parseInt(height)))
    

  };

    const handleMatterChange = (e) => {
    matterSet(e.target.value);

  };

  const handleChangeHeight = (e, value) => {
    heightSet(e.target.value);
    priceSet(priceSelected * (parseInt(e.target.value) + parseInt(width)))
  };

  const handleChangeWidth = (e, value) => {
    widthSet(e.target.value);
    priceSet(priceSelected * (parseInt(e.target.value) + parseInt(height)))
  };

  const handleChangeEmail = (e, value) => {
    
    emailSet(e.target.value)
  };

    const handleChangeName = (e, value) => {
    
    inquirernameSet(e.target.value)
  };

  const handleChangeMessage = (e, value) => {
    messageSet(e.target.value);
  };

  const history = useHistory();
   const handleCommissionSettings = () => {
    
          let path = `/SettingsCommission`; 
          history.push(path);
  };

  


   const body = (
    <div className = 'commissionModal'>

      <div className = 'commissionModalInside'>

      <div onClick = {handleModalClose} className = 'commissionModalCloseIcon'> <CloseIcon/> </div>

      <h2 className = 'commissionModalTitle'> {commissiontype} </h2>

      <p className = 'commissionModalSubTitle'> Please fill each entry of this form before submitting your commission request</p>

      <div className = 'commissionModalBar'>

        <div className = 'commissionModalBarMedia'>

            <p className = 'commissionModalEntryText'> MEDIA </p>

            <Select

                className = 'commissionModalEntryMedia'
                value={media}
                onChange={handleMediaChange}
                variant = "outlined"
                size = "small"  
              >
                {mediaList.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>

              <p className = 'commissionModalEntryText'> MATTER </p>

               <Select

                className = 'commissionModalEntryMedia'
                value={matter}
                onChange={handleMatterChange}
                variant = "outlined"
                size = "small"  
              >
                {matterList.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>

          

          
          
            <p className = 'commissionModalEntryText'> Height </p>
            <div className = 'commissionModalEntryDimensionBlock'>
              <TextField className = 'commissionModalEntryDimension' size = 'small' variant = "outlined" type= "number" placeholder="height" id="height" value={height} onChange={handleChangeHeight}/>
              <p className = 'commissionModalEntryUnits'> inches </p>
            </div>
            <p className = 'commissionModalEntryText'> Width </p>
            <div className = 'commissionModalEntryDimensionBlock'>
              <TextField className = 'commissionModalEntryDimension' size = 'small' variant = "outlined" type= "number" placeholder="width" id="height" value={width} onChange={handleChangeWidth}/>
              <p className = 'commissionModalEntryUnits'> inches </p>
            </div>  

          </div>

        </div>

        <div className = 'commissionModalEntryPrice'> price: {price} $ </div>

        <div>
        
          <p className = 'commissionModalMessageText'> Write a brief message to the Artist explaining the commission you want </p>

          <p className = {!token ? 'commissionModalEntryEmail' : 'galleryDescriptionOff'}> e-mail </p>
          <div style={{display: !token ? '' : 'none'}}> <TextField placeholder = 'insert your email' size = 'small' variant = "outlined" required value={email} onChange={handleChangeEmail}/> </div>
          
          <p className = {!token ? 'commissionModalEntryEmail' : 'galleryDescriptionOff'}> your name </p>
          <div style={{display: !token ? '' : 'none'}}> <TextField placeholder = 'insert your full name' size = 'small' variant = "outlined" required value={inquirername} onChange={handleChangeName}/> </div>
          
          <p className = {!token ? 'commissionModalEntryEmail' : 'galleryDescriptionOff'}> MESSAGE </p>
          <TextField className = 'commissionModalMessage' variant = 'outlined' multiline rows = {4} inputProps={{ maxLength: 500 }} placeholder = 'write here (max 500 characters)' onChange = {handleChangeMessage} value = {message}/>
          
           { token ? 

           <div className = 'commissionModalSendButton'> <Button  disabled = { !media | !matter | !height | !width | !message | height*width<=0} variant = 'outlined' onClick = {requestConversation}> SUBMIT </Button> </div>
           :
           <div className = 'commissionModalSendButton'> <Button  disabled = { !inquirername |!email | !media | !matter | !height | !width | !message | height*width<=0} variant = 'outlined' onClick = {handleInquiry}> SUBMIT </Button> </div>
           }

           <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity={severity}/>
        </div>
      

     </div>      
    </div>
  );


  return (

        <div className = 'userCommissionMain2'>

         
          <div className = {props.owner  ? 'CommissionSettingsButton' : 'gallerySettingsButtonNone'} onClick= {handleCommissionSettings}>   <SettingsIcon/>  </div> 

          <p className = 'commissionText'> Select the  option that better describes the commission you want and see your price</p>
          
          
          <div className = { props.artistInformation.freeform ?'commissionCardContainer2':'commissionInactive'} onClick={props.artistInformation.freeform ? handleFreeForm : '' }>
            <img src = {freeForm} className = 'commissionCardPic2'/>
            <div className = 'commissionCardText'>
              <h2 className = 'commissionCardTextHeader'> Free form</h2>
              <p> You choose dimensions, support, and matter, the artist will create something for you </p>
            </div>
          </div>

          <div className = { props.artistInformation.portrait ?'commissionCardContainer2':'commissionInactive'}  onClick={props.artistInformation.portrait ? handlePortrait: ''}>
            <img src = {portrait} className = 'commissionCardPic2'/>
            <div className = 'commissionCardText'>
              <h2 className = 'commissionCardTextHeader'> portrait</h2>
              <p> Commission a portrait for you or the ones you love!  </p>
            </div>
          </div>

          <div className = { props.artistInformation.model ?'commissionCardContainer2':'commissionInactive'}  onClick={props.artistInformation.model ? handleModel : ''}>
            <img src = {model} className = 'commissionCardPic2'/>
            <div className = 'commissionCardText'>
              <h2 className = 'commissionCardTextHeader'> model </h2>
              <p> Choose one of the artist's portfolio pictures as a model and ask for the same or similar commission with minor changes (colors, dimensions...) </p>
            </div>
          </div>

          <div className = { props.artistInformation.fullcustom ?'commissionCardContainer2':'commissionInactive'}  onClick={props.artistInformation.fullcustom  ? handleFullCustom: ''}>
            <img src = {fullCustom} className = 'commissionCardPic2'/>
            <div className = 'commissionCardText'>
              <h2 className = 'commissionCardTextHeader'> full custom</h2>
              <p> This is your chance to ask for a completely custom artwork!   </p>
            </div>
          </div>

          <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>

          
        
          

       </div >
  );
}

export default UserCommissions;