import React  from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import './User.css';



export default function ProfileBar(props) {


  //const [formats, setFormats] = useState(() => ['portfolio']);

  //const handleFormat = (event, newFormats) => {
    //setFormats(newFormats);
  //};

  const StyledToggleButton = withStyles((theme) => ({
  


  root: {

    "&.Mui-selected": {
            color: 'black'
          },

    border: '0.5px',
    borderStyle: 'solid',
    borderColor: 'grey',
    marginTop: '5px',
    color: 'grey',
    height: 48, 
    fontWeight: '400',
    
  },

}))(ToggleButton);


if(props.userType == 'artist'){

  return (
    <ToggleButtonGroup exclusive size = 'small' value={props.selection} onChange={props.selectionHandler} aria-label="text formatting" className='ToggleButtonGroup'>

      <StyledToggleButton value="portfolio" aria-label="bold" >
        PORTFOLIO
      </StyledToggleButton>
      <StyledToggleButton value="profile" aria-label="italic">
        PROFILE
      </StyledToggleButton>
      <StyledToggleButton value="commissions" aria-label="underlined">
        COMMISSIONS
      </StyledToggleButton>
      
    </ToggleButtonGroup>
  );
}


if(props.userType == 'commissioner'){

  return (
    <ToggleButtonGroup exclusive size = 'small' value={props.selection} onChange={props.selectionHandler} aria-label="text formatting" >

      <StyledToggleButton value="portfolio" aria-label="bold" >
        COLLECTION
      </StyledToggleButton>
      <StyledToggleButton value="profile" aria-label="italic">
        PROFILE
      </StyledToggleButton>
      
      
    </ToggleButtonGroup>
  );
}





}


