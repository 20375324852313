import React, {useState} from 'react';
import  MyGallery from './UserGallery'
import  ProfileBar from './UserBar'
import UserCommissions from './UserCommissions'
import UserProfile from './UserProfile'
import axios from 'axios';
import { useParams, useHistory} from "react-router-dom";
import {BASE_URL, ACCESS_TOKEN_NAME, USER_TYPE, HOME} from '../constants/apiConstants'
import {USER_ID} from '../constants/apiConstants'
import UserHeader from './UserHeader'
import SideLeft from './UserSideLeft'
import NotificationsIcon from '@material-ui/icons/Notifications';
import AlertComponent from './AlertComponent';
import { Button, TextField } from '@material-ui/core';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Modal from '@material-ui/core/Modal';
import emptyGallery from './white_canvas_written.png';

import {EmailShareButton,FacebookShareButton,PinterestShareButton,TwitterShareButton} from "react-share";
import {EmailIcon,FacebookIcon,PinterestIcon,TwitterIcon} from "react-share";



import './User.css'







function User(props) {



  let { user } = useParams();
  



  const [barSelector, changeSelection] = useState(false);
  const [profileInfo, profileInfoUpdate] = useState([]);
  const [artistInfo, artistInfoUpdate] = useState([]);
  const [portfolioURLs, portfolioURLsUpdate] = useState({});
  const [selection, setSelection] = useState(() => ['portfolio']);

  const [modalOpen, modalOpenSet] = useState(false)
  const [errorMessage, updateErrorMessage] = useState(null);
  const [severity, severityUpdate] = useState("success");

  const [collectorMessage, collectorMessageSet] = useState('')
 

  const [user_type, user_typeSet] = useState('artist');
  
  const [followFlag,followFlagSet] = useState({});

  const userID = localStorage.getItem(USER_ID);
  const token = localStorage.getItem(ACCESS_TOKEN_NAME);


  const handleSelection = (event, newFormats) => {
    setSelection(newFormats);
  };
  
  
  




 React.useEffect(() => {

       const token = localStorage.getItem(ACCESS_TOKEN_NAME);
                  


          axios.get(BASE_URL + 'users/get/'+user, {
            headers: {
              'Authorization': 'Bearer '+ token,
            }
          })

          .then((res) => {

              
              profileInfoUpdate(res.data)
              user_typeSet(res.data.user_type)
                
          })

          .catch((error) => {
            console.error(error)
          }) 


          axios.get(BASE_URL + 'users/get/artist/'+user, {
            headers: {
              'Authorization': 'Bearer '+ token,
            }
          })

          .then((res) => {

              
              artistInfoUpdate(res.data)
              
                
          })

          .catch((error) => {
            console.error(error)
          }) 


       axios.get(BASE_URL +  'users/image/portfolio/'+user, {
            headers: {
              'Authorization': 'Bearer '+ token,
            }
          })

          .then((res) => {

              
              
              portfolioURLsUpdate(res.data)
                
          })

          .catch((error) => {
            console.error(error)
          }) 

     if(token){

         axios.get(BASE_URL + 'follows/following?leader='+user, {
              headers: {
                'Authorization': 'Bearer '+ token,
              }
            })

            .then((res) => {

                
                
                followFlagSet(res.data)
                  
            })

            .catch((error) => {
              console.error(error)
            }) 
     }




  //#########################################################
    
       /* const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        
        const requestOne = axios.get(BASE_URL + 'users/get/'+user, {
                                      headers: {
                                        'Authorization': 'Bearer '+ token,
                                      }
                                    });
        const requestTwo = axios.get(BASE_URL + 'users/image/portfolio/'+user, {
                                      headers: {
                                        'Authorization': 'Bearer '+ token,
                                      }
                                    });

        const requestFollow = axios.get(BASE_URL + 'follows/following?leader='+user, {
                                      headers: {
                                        'Authorization': 'Bearer '+ token,
                                      }
                                    });


        axios.all([requestOne, requestTwo, requestFollow])

        .then(
              axios.spread((...responses) => {
                const responseOne = responses[0];
                profileInfoUpdate(responseOne.data)
                user_typeSet(responseOne.data.user_type)
                
                const responseTwo = responses[1];
                portfolioURLsUpdate(responseTwo.data)

                const responseFollow = responses[2];
                followFlagSet(responseFollow.data)
                console.log(responseFollow)
                
                    
                //console.log(responseOne.data, responseTwo);
              })
            )
        .catch(errors => {
                // react on errors.
                console.error(errors);
                  
                
              }); */
           

  }, []);
  

 
   const history = useHistory();

   const redirectToError = () => {
        let path = `./error`; 
        history.push(path);
    }

       const handleFollow = () => {

        const token = localStorage.getItem(ACCESS_TOKEN_NAME);

        axios({
            method: "post",
            url: BASE_URL + "follows/request",
            data: { leader: user,
                    notification_type: 'FOLLOW'},
            headers: {
          'Authorization': 'Bearer '+ token,
          'Content-Type': 'application/json'
        }
      })
          .then(res => {
            console.log(res.data);
            window.location.reload(false)
          })
          .catch(err => console.log(err))
    }

    const handleModalClose = () => {
    modalOpenSet(false);
  };

  const handleModalOpen = () => { 
     modalOpenSet(true);
   } 

   const sendCollectorMessage = () => { 

    const date = new Date()

    axios({
              method: "post",
              url: BASE_URL + "chat/engage/commissioner",
              
              data: { commissioner: user,
                      message: collectorMessage,
                      timestamp: date
                     },

              headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json'
          }
        })
            .then(res => {

              if(res.status === 201){

                        updateErrorMessage("The request was sent! Click anywhere to close the window ");
                        severityUpdate("success")}

  
            })
            .catch(function (error) {

              updateErrorMessage("Something went wronf. Try again or contact us!");
              severityUpdate("warning")

                    
                });
   } 

   const handleCollectorMessage = (e, value) => {
    collectorMessageSet(e.target.value);
  };


   const body = (

    <div className = 'commissionModal'>

      <div className = 'commissionModalInside'>

        <div>
           
          <p className = 'commissionModalMessageText'> Write a brief message </p>
          <TextField className = 'commissionModalMessage' variant = 'outlined' multiline rows = {4} inputProps={{ maxLength: 500 }} placeholder = 'write here (max 500 characters)' value = {collectorMessage} onChange ={handleCollectorMessage}/>
           <div className = 'commissionModalSendButton'> <Button  disabled = {!token} variant = 'outlined' onClick = {sendCollectorMessage}> Send </Button> </div>
           <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity={severity}/>
        </div>
      

     </div>      
    </div>
  );



  
  let content;

    
    if (selection == 'portfolio') {
      content = <div className = 'galleryWrap'>  <MyGallery galleryURL = {portfolioURLs} profileInformation = {profileInfo} userID = {user} owner = {user == userID}/> </div>;
    } 

    if (selection == 'portfolio' & user_type == 'commissioner' &  !portfolioURLs[0]) {
       content = <div className = 'galleryWrap'>  <MyGallery galleryURL = {[
  

              {
                original: require('./white_canvas_written.png'),
                thumbnail: require('./white_canvas_written.png'),
                description: '',
                
              },

            ]} profileInformation = {profileInfo} userID = {user} owner = {user == userID}/> </div>;
       } 

    if (selection == 'profile') {
      content = <UserProfile profileInformation = {profileInfo}/>;
    } 
    if (selection == 'commissions') {
      
      content = <UserCommissions artistInformation = {artistInfo} owner = {user == userID}/>;
    }

  return (

        

        <div className = 'userMain'>


            <div className = "left-bar">
            
                 <UserHeader profileInformation = {profileInfo} userType = {user_type} owner = {user == userID}/>

                 

                 <div className = "followButton">
                     <Button

                      style={{ backgroundColor: followFlag.follows == 'following' ? "rgba(86,162,229,0.2)" : "" , display: user == userID ? "none" : "" }}
                      onClick = {handleFollow}
                      variant="outlined"
                      startIcon={<NotificationsIcon />}
                      disabled = {!token}
                    >
                      
                      { token  ? followFlag.follows : "follow" }

                    </Button>

                     <Button
                      style={{ display: user_type == 'commissioner' & user != userID  ? "" : "none" }}
                      variant="outlined"
                      startIcon={<MailOutlineIcon />}
                      onClick = {handleModalOpen}
                      disabled={!token}
                    >
                      Message
                    </Button>

                </div>

                <SideLeft artistInformation = {artistInfo} profileInformation = {profileInfo} owner = {user == userID} artist = {user_type=='artist'}/> 

            </div>
		 

            <div className = "gallery">

			       
			           <div className = "userBar"> <ProfileBar selectionHandler = {handleSelection}  selection = {selection} userType = {user_type}/> </div>
                 
             
                 
                {content} 



             </div>

             <Modal
                  open={modalOpen}
                  onClose={handleModalClose}
                  
                >
                  {body}
            </Modal>


      </div >
  );
}

export default User;
