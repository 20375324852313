import React, {useState} from 'react';
import './User.css'
import SettingsProfile from './SettingsProfile'
import axios from 'axios';
import { Button } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import ReactQuill from 'react-quill';
import AlertComponent from './AlertComponent';
import {BASE_URL, ACCESS_TOKEN_NAME, USER_ID} from '../constants/apiConstants'
import { useParams} from "react-router-dom";




function UserProfile(props) {

    

    let { user } = useParams();

    const userID = localStorage.getItem(USER_ID); 

    const owner = userID==props.profileInformation.user_id


    const [displayBioSet, setDisplayBioSet] = useState(false);
    const [displayArtSet, setDisplayArtSet] = useState(false);

    const [biography, setBiography] = useState(props.profileInformation.biography);
    const [art, setArt] = useState(props.profileInformation.art);

    const [errorMessage, updateErrorMessage] = useState(null);
    const [severity, severityUpdate] = useState("success");



    const handleSettingsBio = () => {

        setDisplayBioSet(!displayBioSet)

         const token = localStorage.getItem(ACCESS_TOKEN_NAME);

         if(displayBioSet){


                 axios({
                    method: "post",
                    url:  BASE_URL + "users/update/biography",
                    data: {"biography": biography,
                           "art": art},
                    headers: {

                    'Authorization': 'Bearer '+ token,  
                    'Content-Type': 'application/json'

                    }
                })
                        .then(function (response) {
                            if(response.status === 200){

                                updateErrorMessage("Information updated succesfully. Refresh to see changes");
                                severityUpdate("success"); 
                                
                            } else{

                                
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            updateErrorMessage("Information not updated. Please try again");
                            severityUpdate("warning"); 
                            
                            
                        });  

         }


    }
    const handleSettingsArt = () => {

        setDisplayArtSet(!displayArtSet)

        const token = localStorage.getItem(ACCESS_TOKEN_NAME);

         if(displayArtSet){


                 axios({
                    method: "post",
                    url: BASE_URL + "users/update/biography",
                    data: {"biography": biography,
                           "art": art},
                    headers: {

                    'Authorization': 'Bearer '+ token,  
                    'Content-Type': 'application/json'

                    }
                })
                        .then(function (response) {
                            if(response.status === 200){

                                //window.location.reload(false)
                                updateErrorMessage("Information updated succesfully. Refresh to see changes");
                                severityUpdate("success"); 

   
                            } else{

                                
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            updateErrorMessage("Information not updated. Please try again");
                            severityUpdate("warning"); 
                            
                            
                        });  

         }
    }

  

  return (


        <div >

            
            <div className = "profileContentHeader">

               BIOGRAPHY 

               <div style={{marginTop: '-5px', display: owner ? "flex" : "none"}}>
                    <Button onClick={handleSettingsBio}> <SettingsIcon fontSize = "small"/> </Button>
                    <Button variant = 'outlined' style = {{display: !displayBioSet ? "none" : "block"}} onClick={handleSettingsBio}> SAVE </Button>
               </div>

            </div>


            <div className = "profileContent">

             <div style={{display: displayBioSet ? "block" : "none"}}> 
               <SettingsProfile content = {biography} setContent={setBiography} /> 

                    
             </div>

                <div style={{display: displayBioSet ? "none" : "block"}}>
                   <ReactQuill value={biography} readOnly={true} theme="bubble"/>
                </div>

            </div>



            <div className = "profileContentHeader">

               ART

               <div style={{marginTop: '-5px', display: owner ? "flex" : "none"}}>
                    <Button onClick={handleSettingsArt}> <SettingsIcon fontSize = "small"/> </Button>
                    <Button variant = 'outlined' style = {{display: !displayArtSet ? "none" : "block"}} onClick={handleSettingsArt}> SAVE </Button>
               </div>

            </div>

            <div className = "profileContent">

                 <div style={{display: displayArtSet ? "block" : "none"}}> 
                   <SettingsProfile content = {art} setContent={setArt} /> 
                 </div>


                 <div style={{display: displayArtSet ? "none" : "block"}}>
                   <ReactQuill value={art} readOnly={true} theme="bubble"/>
                </div>


            </div>

            <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity={severity}/>

      </div >
  );
}

export default UserProfile;