import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import {USER_ID} from '../constants/apiConstants'




const useStyles = makeStyles((theme) => ({
  
  large: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
  },
}));


function ChatMessage(props) {


	
	const user_id = localStorage.getItem(USER_ID);

  const owner = user_id == props.newmessage.sender_id 

	const classes = useStyles();

  
  var t_stamp = new Date(props.newmessage.timestamp),
            date = t_stamp.toLocaleString()
  
  


  
  if(props.newmessage.message_type == "TEXT"){

    if(props.type == 'main'){
    
    return(

        <div  className =  {owner ? "messageContainerOwner" : "messageContainerOther" }>

         	<div className = 'messageDate'>
           		{date}
           	</div> 

        	<div className = 'messageWrap'>

	           	<Avatar alt="Remy Sharp" src={props.newmessage.image_src} className = 'messageAvatar'/>
	           	<div className = 'messageName'>
	           		{props.newmessage.first_name}
	            </div>  

            </div>   
	        <div className = 'messageText'> 
	           {props.newmessage.message}
	        </div> 
        </div>
    )}

    if(props.type == 'sidebar'){
    
    return(

        <div  style = {{ border: "0px solid grey", backgroundColor: owner ? "rgba(25,77,125,0.15" : "rgba(0,120,0,0.15", borderRadius: '10px', display: 'flex', 
                         marginLeft: owner ? "auto" : '', marginTop:'2px'}}>


          
          <div style={{paddingLeft:"10px", paddingRight:"10px", paddingTop: '2px', paddingBottom:"2px", fontSize:"17px", whiteSpace: 'normal',}}> 
             <span> {props.newmessage.message} </span>
          </div> 
        </div>
    )}
 


  }

    if(props.newmessage.message_type =="IMAGE"){

      if(props.type == 'main'){

      return(

        


          <div  className =  {owner ? "messageContainerOwner" : "messageContainerOther" }>

          <div className = 'messageDate'>
              {date}
            </div> 

          <div className = 'messageWrap'>

              <Avatar alt="Remy Sharp" src={props.newmessage.image_src} className = 'messageAvatar'/>
              <div className = 'messageName'>
                {props.newmessage.first_name}
              </div>  

            </div>   
          <div className = 'messageText'> 
             <img src = {props.newmessage.message} className = 'messageImage'/>
          </div> 

          
            
        </div>


        
        )
      }
    }

    return(

      <div>
      </div>)


}
export default ChatMessage