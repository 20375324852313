import React from "react";
import ReactQuill from 'react-quill';
import './UserProfileQuill.css';

function SettingsProfile(props) {


  






  const setModules = {
  toolbar: [
    [ { 'font': [] }],
    [{size: []}],
    [{align: []}],
    [{color: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote',],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}



  return (

  	<div>

       <ReactQuill  theme="snow"  defaultValue = {props.content} onChange={props.setContent} modules={setModules}/>



    </div>

  );
}

export default SettingsProfile;