import React, {useState} from 'react';
import {TextField, Input, Button } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";

import CutImage from './SettingsPersonalImageCut.js'
import axios from 'axios';
import {BASE_URL, ACCESS_TOKEN_NAME, USER_ID} from '../constants/apiConstants'
import AlertComponent from './AlertComponent';
import userIcon from './user_icon.png';
import './Settings.css'


function SettingsPersonal(props) {


  const [errorMessage, updateErrorMessage] = useState(null);
  const [severity, severityUpdate] = useState("success");


  const [profileInfo, profileInfoUpdate] = useState({});

  const [info , setInfo] = useState({

        firstName : "",
        lastName : "",
        statement : "",
        dob : "",
        city : "",
        state: "",
        country: "",
        education : "",
        gallery : "",
        facebook : "",
        website : "",
        instagram: "",
        behance: ""
        
    })

  countries.registerLocale(enLocale);
  const countryObj = countries.getNames("en", { select: "official" });

  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return value
      
  });

  const USstates = [' ',
                    'Alabama',
                    'Alaska',
                    'Arizona',
                    'Arkansas',
                    'California',
                    'Colorado',
                    'Connecticut',
                    'Delaware',
                    'Florida',
                    'Georgia',
                    'Hawaii',
                    'Idaho',
                    'Illinois',
                    'Indiana',
                    'Iowa',
                    'Kansas',
                    'Kentucky',
                    'Louisiana',
                    'Maine',
                    'Maryland',
                    'Massachusetts',
                    'Michigan',
                    'Minnesota',
                    'Mississippi',
                    'Missouri',
                    'Montana',
                    'Nebraska',
                    'Nevada',
                    'New Hampshire',
                    'New Jersey',
                    'New Mexico',
                    'New York',
                    'North Carolina',
                    'North Dakota',
                    'Ohio',
                    'Oklahoma',
                    'Oregon',
                    'Pennsylvania',
                    'Rhode Island',
                    'South Carolina',
                    'South Dakota',
                    'Tennessee',
                    'Texas',
                    'Utah',
                    'Vermont',
                    'Virginia',
                    'Washington',
                    'West Virginia',
                    'Wisconsin',
                    'Wyoming']

     




React.useEffect(() => {
    
        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        const userID = localStorage.getItem(USER_ID);
        

        axios.get(BASE_URL + 'users/get/'+userID, {
          headers: {
            'Authorization': 'Bearer '+ token,
          }
        })
        .then((res) => {
          
          profileInfoUpdate(res.data)

          setInfo(prevState => ({
            ...prevState,
            firstName : res.data.first_name,
            lastName : res.data.last_name,
            statement : res.data.statements,
            dob : res.data.date_of_birth,
            city : res.data.city,
            state: res.data.state,
            country: res.data.country,
            education : res.data.institution,
            gallery : res.data.gallery,
            facebook : res.data.facebook,
            website : res.data.website,
            instagram: res.data.instagram,
            behance: res.data.behance,
        }))
          

              
        })
        .catch((error) => {
          console.error(error)
        })

      
    

  }, []);





  const handleChange = (e) => {

        console.log(e.target)
        const {id , value} = e.target   
        setInfo(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const handleCountryChange = (e) => {


        console.log(e.target)
        
        setInfo(prevState => ({
            ...prevState,
            'country' : e.target.value
        }))

    }

        const handleStateChange = (e) => {


        console.log(e.target)
        
        setInfo(prevState => ({
            ...prevState,
            'state' : e.target.value
        }))

    }




const handleSubmitClick = (e) => {

        e.preventDefault();
        const payloadInfo={


            "first_name":info.firstName,
            "last_name":info.lastName,
            "date_of_birth":info.dob,
            "city":info.city, 
            "state":info.state,
            "gallery":info.gallery,
            "country":info.country,
            "institution": info.education,  
            "statements": info.statement,
            "facebook" : info.facebook,
            "website" : info.website,
            "instagram": info.instagram,
            "behance": info.behance,

        }

        const token = localStorage.getItem(ACCESS_TOKEN_NAME);
        console.log(payloadInfo)

        axios({
            method: "post",
            url: BASE_URL + "users/update/profile",
            data: payloadInfo,
            headers: {

            'Authorization': 'Bearer '+ token,  
            'Content-Type': 'application/json'

            }
        })
                .then(function (response) {
                    if(response.status === 200){

                        updateErrorMessage("Information updated succesfully");
                        severityUpdate("success");
                        
                        
                    } else{

                        updateErrorMessage("Information not updated. Please try again");
                        severityUpdate("success"); 
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    updateErrorMessage("Information not updated. Please try again");
                    severityUpdate("warning"); 
                    
                });    
         


    
           
    }


  


  

  return (


        <div className = "SettingsPersonalPage">



        <h1 className = 'SettingsPersonalMainTitle'> Settings </h1>
        <p className = "SettingsPersonalHeaders"> PROFILE PICTURE </p>
        <hr className = "SettingsPersonalLine"/>

          

        <div className = 'SettingsPersonalProfilePicWrap'>	 

			       
           
                <img alt = "profile pic" className = 'SettingsPersonalPic' src= {profileInfo.profile_image_url ? profileInfo.profile_image_url : userIcon} />
                                   
             


             <div >
 
             	  <CutImage errorMessage = {updateErrorMessage}  severityUpadate = {severityUpdate} />

             </div>
			       
          </div>

          
         <p className = "SettingsPersonalHeaders"> PERSONAL INFORMATION </p>
         <hr className = "SettingsPersonalLine"/>



        {/*  #########     FORM   ###########         */}

          <form>

                <div  className = "SettingsPersonalItem">
        		  	      <p className= 'SettingsPersonalItemName'> FIRST NAME: </p>
        	      	    <Input inputProps={{ maxLength: 20 }} className = 'SettingsPersonalItemInput' type= "text" placeholder="Your first name" id="firstName" value={info.firstName} onChange={handleChange} />	

                </div>

                <div  className = "SettingsPersonalItem">
                      <p className= 'SettingsPersonalItemName'> LAST NAME: </p>
                      <Input inputProps={{ maxLength: 20 }} className = 'SettingsPersonalItemInput' type= "text" placeholder="Your last name" id="lastName" value={info.lastName} onChange={handleChange} />          
                </div>

                 <div  className = "SettingsPersonalItem">
                      <p className= 'SettingsPersonalItemName'> YOUR MOTTO: </p>
                      <Input inputProps={{ maxLength: 55 }} className = 'SettingsPersonalItemInputStatement' type= "text" placeholder="e.g. painter and sculptor (max 55 char)" id="statement" value={info.statement} onChange={handleChange} style ={{width: '550px'}}/>          
                </div>


                <div  style = {{display:'none'}}/*className = "SettingsPersonalItem"*/> 
              		  	<p className= 'SettingsPersonalItemName'> DATE OF BIRTH: </p>
              	      	<TextField
                        className = 'SettingsPersonalItemInput' 
                        type= "date"
                				id="dob"
                				label="Birthday"
                        value={info.dob}
                        onChange={handleChange}
                				InputLabelProps={{
                  				shrink: true,
                				}}
              			 />
                </div>



                <div  className = "SettingsPersonalItem">
          		  	  <p className= 'SettingsPersonalItemName'> CITY: </p>
          	      	<Input inputProps={{ maxLength: 50 }} className = 'SettingsPersonalItemInput' type= "text" placeholder="Where do you live?" id="city" value={info.city} onChange={handleChange} />
                </div>

                <div  className = "SettingsPersonalItem">
                    <p className= 'SettingsPersonalItemName'> STATE: </p>
                    
                        
                     <TextField
                            style = {{ width: '200px'}}
                            
                            value={info.state}
                            select
                            onChange={handleStateChange}
  
                          >
                            {   !!countryArr?.length &&
                                USstates.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                     </TextField>
                </div>

                <div  className = "SettingsPersonalItem">
                    <p className= 'SettingsPersonalItemName'> COUNTRY: </p>
                    

                     <TextField
                            style = {{ width: '200px'}}
                            variant = 'standard'
                            
                            value={info.country}
                            select
                            onChange={handleCountryChange}
  
                          >
                            {   !!countryArr?.length &&
                                countryArr.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                     </TextField>
                </div>


                <div  className = "SettingsPersonalItem">
        		  	  <p className= 'SettingsPersonalItemName'> EDUCATION: </p>
        	      	<Input inputProps={{ maxLength: 50 }} className = 'SettingsPersonalItemInput' type= "text" placeholder="Institution" id="education" value={info.education} onChange={handleChange} />       
                </div>


                <div  className = "SettingsPersonalItem">
        		  	   <p className= 'SettingsPersonalItemName'> GALLERY: </p>
        	      	 <Input inputProps={{ maxLength: 50 }} className = 'SettingsPersonalItemInput' type= "text" placeholder="Gallery name" id="gallery" value={info.gallery} onChange={handleChange} />
                </div>

            </form>

            <div className = "SettingsPersonalSubmitButton">
                  <Button variant="outlined"  onClick={handleSubmitClick}>Submit</Button>
            </div>

         <p className = "SettingsPersonalHeaders"> LINKS AND SOCIAL MEDIA </p>
         <hr className = "SettingsPersonalLine"/>

                <div  className = "SettingsPersonalItem">
                    <p className= 'SettingsPersonalItemName'> WEBSITE: </p>
                    <Input className = 'SettingsPersonalItemInput' type= "text" placeholder="artmecenate.com" id="website" value={info.website} onChange={handleChange} />
                </div>

                 <div  className = "SettingsPersonalItem">
                    <p className= 'SettingsPersonalItemName'> FACEBOOK PROFILE: </p>
                    <Input className = 'SettingsPersonalItemInput' type= "text" placeholder="facebook.com/YourPage" id="facebook" value={info.facebook} onChange={handleChange} style ={{width: '250px'}}/>
                </div>

                <div  className = "SettingsPersonalItem">
                    <p className= 'SettingsPersonalItemName'> INSTAGRAM PROFILE: </p>
                    <Input className = 'SettingsPersonalItemInput' type= "text" placeholder="instagram.com/YourPage" id="instagram" value={info.instagram} onChange={handleChange} style ={{width: '250px'}}/>
                </div>

                <div  className = "SettingsPersonalItem">
                    <p className= 'SettingsPersonalItemName'> BEHANCE PROFILE: </p>
                    <Input className = 'SettingsPersonalItemInput' type= "text" placeholder="behance.net/YourPage" id="behance" value={info.behance} onChange={handleChange} style ={{width: '250px'}}/>
                </div>


            <div className = "SettingsPersonalSubmitButton">
                  <Button variant="outlined"  onClick={handleSubmitClick}>Submit</Button>
            </div>



            <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} severity={severity}/>






        </div>


  )
}

export default SettingsPersonal;
