import ImageGallery from 'react-image-gallery';
import React, {useRef, useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from "react-router-dom";
import './image-gallery.css'
import './User.css'

import {EmailShareButton,FacebookShareButton,PinterestShareButton,TwitterShareButton} from "react-share";
import {EmailIcon,FacebookIcon,PinterestIcon,TwitterIcon} from "react-share";






function MyGallery(props) {

  /*
  const images = [
  {
    original: props.galleryURL.image1,
    thumbnail: props.galleryURL.image1,
    description: 'Road through the hills: oil on canvas, 50x70',

  },
  {
    original: props.galleryURL.image2,
    thumbnail: props.galleryURL.image2,
    description: 'Oil on canvas 20X20',
    
  },
  {
    original: props.galleryURL.image3,
    thumbnail: props.galleryURL.image3,
    description: 'Oil on canvas 30X30',
    
  },

  {
    original: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Mona_Lisa%2C_by_Leonardo_da_Vinci%2C_from_C2RMF_retouched.jpg/1200px-Mona_Lisa%2C_by_Leonardo_da_Vinci%2C_from_C2RMF_retouched.jpg',
    thumbnail: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Mona_Lisa%2C_by_Leonardo_da_Vinci%2C_from_C2RMF_retouched.jpg/1200px-Mona_Lisa%2C_by_Leonardo_da_Vinci%2C_from_C2RMF_retouched.jpg',
    description: 'Oil on canvas 30X30',
    
  },

];
*/


   const images = []
   const descriptions = ['']
   const currentImage = ['']

   const imgGalleryRef = useRef();
   const [index, indexSet] = useState(0)

   //const [description, descriptionSet] = useState('')
   
   
   
   for (const [key, value] of Object.entries(props.galleryURL)) {

          images.push(value);
          descriptions[key] = value.description
          currentImage[key] = value.original
        }
  
    

     const indexUpdate = () => {

        if(imgGalleryRef.current){
        indexSet(imgGalleryRef.current.state.currentIndex) 
     }

    }

    const history = useHistory();
    
    const handleGallerySettings = () => {
    
          let path = `/SettingsPortfolio`; 
          history.push(path);
  };
    

    return (

      <div>

      <div className = {props.owner ? 'gallerySettingsButton' : 'gallerySettingsButtonNone'} onClick= {handleGallerySettings}>   <SettingsIcon/>  </div>
      

       <ImageGallery items={images} showPlayButton={false} thumbnailPosition="right" ref={i => imgGalleryRef.current = i} onSlide={indexUpdate} description = {false}/>

       <div className = {descriptions[index].split(';')[0] ? "galleryDescription" : "galleryDescriptionOff"}>

          <p className = "galleryDescriptionTitle"> title:  </p>

          <p className = "galleryDescriptionEntry"> {descriptions[index].split(';')[0]} - </p> 

          <p className = "galleryDescriptionEntry"> {descriptions[index].split(';')[3]} inch, </p> 

          <p className = "galleryDescriptionEntry"> {descriptions[index].split(';')[2]} - </p> 

          <p className = "galleryDescriptionTitle"> author:  </p>

          <p className = "galleryDescriptionEntry"> {descriptions[index].split(';')[1]} </p> 

       </div>

       <div className = 'socialMediaSharing'>

                
                
                <EmailShareButton

                      
                    url= {'artmecenate.com/'+props.userID}
                    hashtag = {'artmecenate'}
                          >
                          
                      <EmailIcon logoFillColor="white" size ={30} round = {true} />
                </EmailShareButton> 

                <FacebookShareButton

                    
                    
                    url= {'https://mecenate-share-private.vercel.app/'+ props.userID + '/' + index}
                    
                          >
                          
                      <FacebookIcon logoFillColor="white" size ={30} round = {true} />
                </FacebookShareButton>


                <TwitterShareButton

                    url= {'https://mecenate-share-private.vercel.app/'+ props.userID + '/' + index}
                    title ={props.profileInformation.first_name +' '+props.profileInformation.last_name +' - '+ descriptions[index].split(';')[0]}
                    via = 'Mecenate'
                    
                          >
                          
                      <TwitterIcon logoFillColor="white" size ={30} round = {true} />
                </TwitterShareButton>

                <PinterestShareButton
                    
                    url= {'https://mecenate-share-private.vercel.app/'+ props.userID + '/' + index}
                    media = {currentImage[index]}
                    description ={props.profileInformation.first_name +' '+props.profileInformation.last_name +' - '+ descriptions[index].split(';')[0]}
                          >
                          
                      <PinterestIcon logoFillColor="white" size ={30} round = {true} />
                </PinterestShareButton>

       </div>
        

      </div>


      )

  
}

export default MyGallery