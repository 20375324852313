import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import BrushIcon from '@material-ui/icons/Brush';
import Badge from '@material-ui/core/Badge';
import NotificationConversation from './NotificationConversation.js'
import NotificationFollow from './NotificationFollow.js'
import axios from 'axios';

import {BASE_URL, ACCESS_TOKEN_NAME, USER_ID, USER_TYPE, ACCESS_TICKET_NAME, HOME} from '../constants/apiConstants'


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));





export default function NotificationMenu() {


   const [conversationRequestList, conversationRequestListSet] = useState([])
   const [userNotifications, userNotificationsSet] = useState([])

   const [notificationCount, notificationCountSet] = useState(0)
   const token = localStorage.getItem(ACCESS_TOKEN_NAME);

   const user_type = localStorage.getItem(USER_TYPE)

   const ticket = localStorage.getItem(ACCESS_TICKET_NAME);
  



    const logoutOnError = () => {
    

        localStorage.removeItem(ACCESS_TOKEN_NAME)
        localStorage.removeItem(USER_ID)
        localStorage.removeItem(ACCESS_TICKET_NAME)

        //console.log('you are out');

        let path = HOME; 
        window.location = path;

        
        
        axios.get(BASE_URL + 'oauth/logout/'+ ticket, {
              headers: {
                'Authorization': 'Bearer '+ token,
              }
            })
        .then((res) => {
                console.log(res)
            })
   

  };

   

   useEffect(() => {

          const token = localStorage.getItem(ACCESS_TOKEN_NAME);
          const pointer = (user_type == 'artist' ? 0:2)
          
           

          
          //const userID = localStorage.getItem(USER_ID);
      
{/*}
          axios.get('http://34.219.113.18:8008/chat/conversation?status=PENDING', {
            headers: {
              'Authorization': 'Bearer '+ token,
            }
          })

          .then((res) => {

              console.log(res.data)
              conversationRequestListSet(res.data)
                
          })

          .catch((error) => {
            console.error(error)
          })  

        */}

          // ################

        if (token){  

            

            const requestOne =  (user_type == 'artist' ? (axios.get(BASE_URL + 'commissions/notifications?status=PENDING', {                     
                                          
                                          headers: {
                                            'Authorization': 'Bearer '+ token,                           
                                          }

                                        }))

                                        : 

                                        (axios.get(BASE_URL + 'chat/pendings?status=PENDING', {
                         
                                          headers: {
                                            'Authorization': 'Bearer '+ token,
                                          }
                                        })))



            const requestTwo = axios.get(BASE_URL + 'follows/notifications?offset=0&limit=10', {
                                          
                                          headers: {
                                           'Authorization': 'Bearer '+ token,                                            
                                          }
                                        });



            axios.all([requestOne, requestTwo])

            .then(

              
                  axios.spread((...responses) => {

                    

                    const responseOne = responses[0];
                    conversationRequestListSet(responseOne.data)
                    //console.log(responseOne)
                    
                    
                    const responseTwo = responses[1];
                    userNotificationsSet(responseTwo.data)

                    
                    
                    
                    var counter = 0

                    const mapping1 = responseOne.data.map((obj, i) => (

                        counter = i+1     
                     ))

                    const mapping2 = responseTwo.data.forEach((obj, i) => 

                      {  
                          if(obj.notification_status == 'UNSEEN'){
                          counter = counter + 1}

                        }
                        
                           
                     )

                    notificationCountSet(counter)
                    
                    
                    
                        
                    console.log(responses);
                  })
                )
            .catch(function (error) {
                  
                 if(error.response.status===401){
                    logoutOnError()
                 }

                    
                    

                  
                });

      }
      
             

   }, []);


    useEffect(() => {

      const token = localStorage.getItem(ACCESS_TOKEN_NAME);
      const pointer = (user_type == 'artist' ? 0:2)

      if(token){


            const interval = setInterval(() => {

                const token = localStorage.getItem(ACCESS_TOKEN_NAME);
                //const userID = localStorage.getItem(USER_ID);
            
      {/*}
                axios.get('http://34.219.113.18:8008/chat/conversation?status=PENDING', {
                  headers: {
                    'Authorization': 'Bearer '+ token,
                  }
                })

                .then((res) => {

                    console.log(res.data)
                    conversationRequestListSet(res.data)
                      
                })

                .catch((error) => {
                  console.error(error)
                })  

              */}

                // ################

              const requestOne =  (user_type == 'artist' ? (axios.get(BASE_URL + 'commissions/notifications?status=PENDING', {                     
                                          
                                          headers: {
                                            'Authorization': 'Bearer '+ token,                           
                                          }

                                        }))

                                        : 

                                        (axios.get(BASE_URL + 'chat/pendings?status=PENDING', {
                         
                                          headers: {
                                            'Authorization': 'Bearer '+ token,
                                          }
                                        })))


              const requestTwo = axios.get(BASE_URL + 'follows/notifications?offset=0&limit=10', {
                                            headers: {
                                              'Authorization': 'Bearer '+ token,
                                            }
                                          });
              

              axios.all([requestOne, requestTwo])

              .then(
                    axios.spread((...responses) => {


                      const responseOne = responses[0]

                      conversationRequestListSet(responseOne.data)
                      
                      
                      const responseTwo = responses[1];
                      userNotificationsSet(responseTwo.data)
                      
                      
                      var counter = 0

                      const mapping1 = responseOne.data.map((obj, i) => (

                          counter = i+1     
                       ))

                      const mapping2 = responseTwo.data.forEach((obj, i) => 


                        {  
                            if(obj.notification_status == 'UNSEEN'){
                            counter = counter + 1}

                          }
                          
                             
                       )

                      notificationCountSet(counter)

                      
                      
                          
                      //console.log(responseOne.data, responseTwo);
                    })
                  )
              .catch(errors => {
                      // react on errors.
                      console.error(errors);

                        
                      
                    });


             }, 20000);
          return () => clearInterval(interval); 

        } 
             

   }, []);


  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    const token = localStorage.getItem(ACCESS_TOKEN_NAME);

        axios({
            method: "post",
            url: BASE_URL + "follows/request",
            data: { leader: 'READ',
                    notification_type: 'SERVICE'},
            headers: {
          'Authorization': 'Bearer '+ token,
          'Content-Type': 'application/json'
        }
      })
          .then(res => {
            
          })
          .catch(err => console.log(err))

  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  

  return (
    <div>
      <IconButton
        onClick={handleClick}
        color='inherit'
        disabled = {!token || !(conversationRequestList[0] || userNotifications[0])}
      >
        <Badge badgeContent={notificationCount} color="primary">
          <NotificationsNoneIcon/>
        </Badge>
      </IconButton>


      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}

      >
        
       
        

            <List style={{display: conversationRequestList[0] ? "flex" : "none", marginLeft: 'auto'}}>

            { 

                conversationRequestList.map((notification, i) => (

                    <MenuItem> 
                      <NotificationConversation key = {i} notification = {notification}/>
                    </MenuItem>     
                    ))}

            </List>
         
          
        
             <List style={{marginTop: '-8px', marginBottom:'-8px'}}>

            { 

                userNotifications.map((notification, i) => (

                    
                    <NotificationFollow key = {i} notification = {notification}/>
                    

                    ))}

            </List>

        
      </StyledMenu>

    </div>
  );
}