import React from 'react';
import logo from './Logo_adam_color.png'; // Tell webpack this JS file uses this image







function LogoAdam() {
  // Import result is the URL of your image
  return <img style = {{height: "200px"}}  src={logo}  alt="Logo_adam" />;
}
export default LogoAdam;