import React from 'react';
import './User.css';
import useFitText from "use-fit-text";
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';
import Behance from './behance.png';
import { useHistory } from "react-router-dom";

import Link from '@material-ui/core/Link';





export default function UserHeader(props) {


    const handleInstagram = () => {

        var url = props.profileInformation.instagram

        if (!props.profileInformation.instagram.match(/^https?:\/\//i)) {
          url = 'http://' + props.profileInformation.instagram;
         }

        window.open(url, '_blank');
    }

    const handleFacebook = () => {
      
        var url = props.profileInformation.facebook

        if (!props.profileInformation.facebook.match(/^https?:\/\//i)) {
          url = 'http://' + props.profileInformation.facebook;
         }
        window.open(url, '_blank');
    }

    const handleWebsite = () => {

        var url = props.profileInformation.website

        if (!props.profileInformation.website.match(/^https?:\/\//i)) {
          url = 'http://' + props.profileInformation.website;
         }
        window.open(url, '_blank');
    }

    const handleBehance = () => {

        var url = props.profileInformation.behance

        if (!props.profileInformation.behance.match(/^https?:\/\//i)) {
          url = 'http://' + props.profileInformation.behance;
         }
        window.open(url, '_blank');
    }

    const history = useHistory();

    const goToProfileSettings = () => {

      if(props.owner){
           
           history.push('/settingspersonal')

      }
    }


  return (
    

          <div className = 'headerContainer'>

          

            <div className = 'pictureAndName'>

             <div className =  'header-img'>

                 <div className = {props.owner ? 'header-img-circle': 'header-img-circle-none'} onClick={goToProfileSettings}>

                  <img alt = "profile pic" className = 'header-img-img'

                     src   = {props.profileInformation.profile_image_url}
                  />
                  </div>

                  <p className = 'userHeaderUserType'> {props.userType == 'artist' ? 'ARTIST' : 'COLLECTOR'}</p>

             </div>

             <h2 className = "header-name" > {props.profileInformation.first_name} {props.profileInformation.last_name}</h2>

             </div>


             <div className = "header-statement-social">
                 
                 <div className = 'header-statement'>
                  <p> {props.profileInformation.statements}</p>
                 </div>

                 <div className = 'header-social'>

                   <IconButton onClick = {handleWebsite} style = {{display: props.profileInformation.website ? "" : 'none'}} ><LanguageIcon /></IconButton>
                   <IconButton onClick = {handleFacebook} style = {{display: props.profileInformation.facebook ? "" : 'none'}} > <FacebookIcon /> </IconButton>
                   <IconButton onClick = {handleInstagram} style = {{display: props.profileInformation.instagram ? "" : 'none'}} ><InstagramIcon /></IconButton>
                   <IconButton onClick = {handleBehance} style = {{display: props.profileInformation.behance ? "" : 'none'}}><img src  = {Behance} style = {{height: '24px'}}/></IconButton>
                   
                 </div>

             </div>

             

         </div>
  );
}
